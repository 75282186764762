import { faArrowRightFromBracket, faBell } from '@fortawesome/pro-regular-svg-icons'
import { Badge, Box, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { IconButton } from 'src/components/IconButton/IconButton'
import { Modal } from 'src/components/Modal/Modal'
import { NavHeader, StyledLogo, StyledLogoContainer } from 'src/components/NavHeader/NavHeader'
import { NavLink } from 'src/components/NavHeader/NavLink'
import { useNotificationSidebarContext } from 'src/components/NotificationSidebar/NotificationSidebarContext'
import { resetState } from 'src/store/reducers/rootReducer'
import { useAppDispatch } from 'src/store/store'
import { logoutUser } from 'src/store/thunks/logoutUser'
import { pxToRem } from 'src/styles/themes'
import { locationMap } from './NavigationFragment'

export type HeaderFragmentProps = {
  //
}

export const HeaderFragment: React.FC<HeaderFragmentProps> = () => {
  const [isLogoutDialogOpen, setLogoutDialogOpen] = useState(false)
  const { notificationCount, setNotificationSidebarOpen } = useNotificationSidebarContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const logoSrc = isMobile ? '/aside-logo-icon.svg' : '/aside-logo.svg'
  const location = useLocation()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onLogout = () => {
    dispatch(logoutUser()).then(() => {
      dispatch(resetState())
      navigate('/')
    })
  }

  const onNotificationSidebarOpen = () => {
    setNotificationSidebarOpen(true)
  }

  return (
    <StyledNavHeader>
      <Grid container justifyContent={'space-between'} alignItems={'fle'}>
        <Grid item xs={4} sm={'auto'}>
          <StyledLogoContainer href={'/explore'} sx={{ marginRight: pxToRem(16), float: 'left' }}>
            <StyledLogo src={logoSrc} />
          </StyledLogoContainer>
          {!isMobile && (
            <StyledNavLinksContainer >
              <NavLink to={'/explore'}>Explore</NavLink>
              <NavLink to={'/bookings'}>Bookings</NavLink>
              <NavLink to={'/favourites'}>Favourites</NavLink>
            </StyledNavLinksContainer>
          )}
        </Grid>
        <StyledGridTitle item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <StyledTypography variant="h1">
            {locationMap[location.pathname as keyof typeof locationMap] ?? ''}
          </StyledTypography>
        </StyledGridTitle>
        <Grid item xs={4} sm={'auto'}>
          <Box sx={{ float: 'right' }} >
            <StyledNotificationButton primary sx={{ marginRight: pxToRem(8) }} onClick={onNotificationSidebarOpen}>
              <Badge sx={{ fontWeight: 400 }} badgeContent={notificationCount} color="error">
                <Icon icon={faBell} sx={{ fontSize: '24px', color: 'white' }} />
              </Badge>
            </StyledNotificationButton>
            <IconButton primary sx={{ marginRight: pxToRem(-12) }} onClick={() => { setLogoutDialogOpen(true) }}>
              <Icon icon={faArrowRightFromBracket} sx={{ fontSize: '24px', color: 'white' }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Modal title={'Confirm sign out'} open={isLogoutDialogOpen} onClose={() => { setLogoutDialogOpen(false) }}>
        <Grid container justifyContent={'flex-end'} spacing={1}>
          <Grid item>
            <Button primary onClick={onLogout}>Sign Out</Button>
          </Grid>
        </Grid>
      </Modal>
    </StyledNavHeader>
  )
}

const StyledNavHeader = styled(NavHeader)`
  width: 100%;
`

const StyledGridTitle = styled(Grid)`
  ${props => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`

const StyledTypography = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  font-weight: 500;
`
// sx={{ height: '100%', paddingLeft: pxToRem(64), display: 'flex', justifyContent: 'center', alignItems: 'center' }}
const StyledNavLinksContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: ${pxToRem(16)};

  ${props => props.theme.breakpoints.up('md')} {
    padding-left: ${pxToRem(64)};
  }
`

const StyledNotificationButton = styled(IconButton)`
  margin-right: ${pxToRem(8)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-right: ${pxToRem(16)};
  }
`