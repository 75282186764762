/* eslint-disable import/no-named-as-default-member */
import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Layout } from './fragments/Layout/Layout'
import initializeDayJs from './libs/dayjsConfig'
import { Bookings } from './pages/Bookings/Bookings'
import { ChangePasswordPage } from './pages/ChangePasswordPage'
import { Explore } from './pages/Explore/Explore'
import { Favourites } from './pages/Favourites/Favourites'
import { LoginPage } from './pages/LoginPage'
import { Onboarding } from './pages/Onboarding/Onboarding'
import { ResetPasswordPage } from './pages/ResetPasswordPage'
import reportWebVitals from './reportWebVitals'
import { persistor, store, useAppDispatch, useAppSelector } from './store/store'
import { setupApp } from './store/thunks/setupApp'
import './styles/GlobalCSS.css'
import { GlobalThemeProvider } from './styles/GlobalThemeProvider'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

const App: React.FC = () => {
  initializeDayJs()

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalThemeProvider>
            <BrowserRouter future={{ v7_startTransition: true }}>
              <ConnectAppRoutes />
            </BrowserRouter>
          </GlobalThemeProvider>
        </PersistGate>
      </Provider>
    </div>
  )
}

const ConnectAppRoutes: React.FC = () => {
  const location = useLocation()
  const user = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (user.sessionid !== null) {
      dispatch(setupApp({}))
    }
  }, [user.sessionid])

  return (
    <>
      <AnimatePresence mode={'wait'}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/reset_password" element={<ResetPasswordPage />} />
          <Route path="/redirect/change_password/" element={<ChangePasswordPage />} />
          <Route element={<Layout />} >
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/favourites" element={<Favourites />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </>
  )
}

root.render(
  <App />,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
