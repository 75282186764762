import { ComponentProps } from 'react'
import { imagesSelectors } from 'src/store/adapters/images'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { likeVehicle } from 'src/store/thunks/likeVehicle'
import { unlikeVehicle } from 'src/store/thunks/unlikeVehicle'
import { Vehicle } from 'src/types/Vehicle'
import { VehicleImage } from 'src/types/VehicleImage'
import { VehicleCard } from '../../components/VehicleCard/VehicleCard'


export type VehicleCardFragmentProps = Omit<ComponentProps<typeof VehicleCard>, 'vehicle' | 'image' | 'onLike'> & {
  vehicleId: Vehicle['VEHICLE_ID']
}


export const VehicleCardFragment: React.FC<VehicleCardFragmentProps> = ({ vehicleId, ...props }) => {
  const vehicle = useAppSelector((state) => vehiclesSelectors.selectById(state, vehicleId))
  const image: VehicleImage = useAppSelector(state => imagesSelectors.selectById(state, vehicle?.FILE)) || null
  
  const dispatch = useAppDispatch()

  const onToggleLike = () => {
    if (vehicle.isLiked) {
      dispatch(unlikeVehicle(vehicle))
    } else {
      dispatch(likeVehicle(vehicle))
    }
  }
  
  return (
    <VehicleCard
      vehicle={vehicle}
      image={image}
      onLike={onToggleLike}
      {...props}
    />
  )
}
