import { createEntityAdapter } from '@reduxjs/toolkit'
import { Vehicle } from 'src/types/Vehicle'
import { RootState } from '../store'

export const vehiclesAdapter = createEntityAdapter({
  selectId: (vehicle: Vehicle) => vehicle.VEHICLE_ID,
})

export const vehiclesSelectors = vehiclesAdapter.getSelectors<RootState>(
  (state) => state.vehicles,
)
