import { createEntityAdapter } from '@reduxjs/toolkit'
import { Manager } from 'src/types/Manager'
import { RootState } from '../store'

export const membersAdapter = createEntityAdapter({
  selectId: (member: Manager) => member.TEAM_MEMBER_ID,
})

export const membersSelectors = membersAdapter.getSelectors<RootState>(
  (state) => state.members,
)
