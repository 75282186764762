import React, { ComponentProps } from 'react'
import ContentLoader from 'react-content-loader'

export type VehicleCardProps = ComponentProps<typeof ContentLoader> & {
  //
}

export const VehicleCardPlaceholder: React.FC<VehicleCardProps> = ({ ...props }) => {
  return (
    <ContentLoader
      width={'100%'}
      height={'100%'}
      viewBox="0 0 500 300"
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      {...props}
    >
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="75%" />
      <rect x="0" y="79%" rx="4" ry="4" width="48" height="24" />
      <rect x="64" y="79%" rx="4" ry="4" width="48" height="24" />
      <rect x="128" y="79%" rx="4" ry="4" width="48" height="24" />
      <rect x="192" y="79%" rx="4" ry="4" width="48" height="24" />
      <rect x="0" y="90%" rx="4" ry="4" width="80%" height="24" />
    </ContentLoader>
  )
}
