import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { VehicleImage } from 'src/types/VehicleImage'
import { imagesAdapter } from '../adapters/images'

export const initialState = imagesAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'images',
  initialState,
  reducers: {
    addMultipleImages: (state, action: PayloadAction<VehicleImage[]>) => {
      imagesAdapter.upsertMany(state, action.payload)
    },
    replaceAllImages: (state, action: PayloadAction<VehicleImage[]>) => {
      imagesAdapter.setAll(state, action.payload)
    },
  },
})


export const { addMultipleImages, replaceAllImages } = actions