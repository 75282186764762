import { faCalendar, faCar, faHeart } from '@fortawesome/pro-regular-svg-icons'
import { useLocation } from 'react-router-dom'
import { SubNavItem } from 'src/components/SubNavSidebar/SubNavItem'
import { SubNavSidebar } from 'src/components/SubNavSidebar/SubNavSidebar'

export type NavigationFragmentProps = {
  //
}

export const locationMap = {
  '/explore': 'Explore',
  '/bookings': 'Bookings',
  '/favourites': 'Favourites',
  '/onboarding': 'Onboarding',
}

export const NavigationFragment: React.FC<NavigationFragmentProps> = () => {
  const location = useLocation()
  const isOnboarding = location.pathname === '/onboarding'

  return isOnboarding ? null : (
    <SubNavSidebar>
      <SubNavItem
        href="/explore"
        title={'Explore'}
        icon={faCar}
      />
      <SubNavItem
        href="/bookings"
        title={'Bookings'}
        icon={faCalendar}
      />
      <SubNavItem
        href="/favourites"
        title={'Favourites'}
        icon={faHeart}
      />
    </SubNavSidebar>
  )
}