import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Customer } from 'src/types/Customer'
import { customersAdapter } from '../adapters/customers'

export const initialState = customersAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    addMultipleCustomers: (state, action: PayloadAction<Customer[]>) => {
      customersAdapter.upsertMany(state, action.payload)
    },
    replaceAllCustomers: (state, action: PayloadAction<Customer[]>) => {
      customersAdapter.setAll(state, action.payload)
    },

  },
})

export const { addMultipleCustomers, replaceAllCustomers } = actions