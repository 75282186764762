import { styled } from '@mui/material'

export type SubNavSidebarProps = React.ComponentProps<'aside'> & {
  children: React.ReactNode
}

export const SubNavSidebar: React.FC<SubNavSidebarProps> = ({ children, ...props }) => {
  return (
    <StyledSubNavSidebar {...props}>
      {children}
    </StyledSubNavSidebar>
  )
}


export const StyledSubNavSidebar = styled('aside', { shouldForwardProp: (prop) => prop !== '$open' },
) <{ $open?: boolean }>`
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndex.appBar + 101};
  background-color: ${(props) => props.theme.palette.custom.subSidebar};


  ${(props) => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`
