import { Box, Grid, Tab, Tabs, Typography, styled } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { BookingCard } from 'src/components/BookingCard/BookingCard'
import { TabPanel } from 'src/components/Tabs/TabPanel'
import { bookingsSelectors } from 'src/store/adapters/bookings'
import { useAppSelector } from 'src/store/store'
import { scrollbarStyle } from 'src/styles/themes'

export type MyBookingsProps = {
  setActiveBookingId: (bookingId: string) => void
}

export const MyBookings: React.FC<MyBookingsProps> = ({ setActiveBookingId }) => {
  const bookings = useAppSelector(state => bookingsSelectors.selectAll(state))
  const [activeTab, setActiveTab] = useState(0)
  const [offsetHeight, setOffsetHeight] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('upcomingBookings')

      if (element) {
        setOffsetHeight(element.offsetHeight)
      }
    }, 50)
  }, [bookings])

  const currentBookings = bookings.filter(booking => {
    const isBookingCancelled = booking.booking_cancelled !== '0000-00-00 00:00:00'
    const isBookingCompleted = booking.booking_completed !== '0000-00-00 00:00:00'

    return !isBookingCancelled && !isBookingCompleted
  }).sort((a, b) => {
    const dateA = dayjs(a.booking_dt_pickup, 'YYYY-MM-DD HH:mm:ss')
    const dateB = dayjs(b.booking_dt_pickup, 'YYYY-MM-DD HH:mm:ss')

    return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0
  })
  
  const completedBookings = bookings.filter(booking => {
    return booking?.booking_completed !== '0000-00-00 00:00:00'
  })
  const canceledBookings = bookings.filter(booking => {
    return booking.booking_cancelled !== '0000-00-00 00:00:00'
  })

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  return (
    <>
      <StyledTabsContainer>
        <StyledTabs value={activeTab} onChange={handleChange} aria-label="booking tabs">
          <StyledTab value={0} disableRipple label="Current" />
          <StyledTab value={1} disableRipple label="Completed" />
          <StyledTab value={2} disableRipple label="Cancelled" />
        </StyledTabs>
      </StyledTabsContainer>
      <StyledSwipableViews
        index={activeTab}
        onChangeIndex={(index) => {
          setActiveTab(index)
        }}
        enableMouseEvents
      >
        <StyledTabPanel $offsetHeight={offsetHeight} value={activeTab} index={0}>
          <StyledContainer>
            <StyledGridContainer container flexDirection={'column'} rowSpacing={2}>
              {currentBookings && currentBookings.map(booking => {
                return (
                  <Grid item key={booking.BOOKING_ID}>
                    <BookingCard booking={booking} onClick={() => setActiveBookingId(booking.BOOKING_ID)} />
                  </Grid>
                )
              })}
              {!currentBookings || currentBookings?.length === 0 && (
                <Grid item>
                  <StyledDisabledText variant="body1">No bookings</StyledDisabledText>
                </Grid>
              )}
            </StyledGridContainer>
          </StyledContainer>
        </StyledTabPanel>
        <StyledTabPanel $offsetHeight={offsetHeight} value={activeTab} index={1}>
          <StyledContainer>
            <StyledGridContainer container flexDirection={'column'} rowSpacing={2}>
              {completedBookings && completedBookings.map(booking => {
                return (
                  <Grid item key={booking.BOOKING_ID}>
                    <BookingCard booking={booking} onClick={() => setActiveBookingId(booking.BOOKING_ID)} />
                  </Grid>
                )
              })}
              {!completedBookings || completedBookings?.length === 0 && (
                <Grid item>
                  <StyledDisabledText variant="body1">No bookings</StyledDisabledText>
                </Grid>
              )}
            </StyledGridContainer>
          </StyledContainer>
        </StyledTabPanel>
        <StyledTabPanel $offsetHeight={offsetHeight} value={activeTab} index={2}>
          <StyledContainer>
            <StyledGridContainer container flexDirection={'column'} rowSpacing={2}>
              {canceledBookings && canceledBookings.map(booking => {
                return (
                  <Grid item key={booking.BOOKING_ID} >
                    <BookingCard booking={booking} onClick={() => setActiveBookingId(booking.BOOKING_ID)} />
                  </Grid>
                )
              })}
              {!canceledBookings || canceledBookings?.length === 0 && (
                <Grid item>
                  <StyledDisabledText variant="body1">No bookings</StyledDisabledText>
                </Grid>
              )}
            </StyledGridContainer>
          </StyledContainer>
        </StyledTabPanel>
      </StyledSwipableViews>
    </>
  )
}

const StyledSwipableViews = styled(SwipeableViews)`
  margin-left: ${props => props.theme.typography.pxToRem(-16)};
  margin-right: ${props => props.theme.typography.pxToRem(-16)};
`

const StyledTabPanel = styled(TabPanel, { shouldForwardProp: (prop) => prop !== '$offsetHeight' }) <{ $offsetHeight: number }>`
  height: calc(100dvh - ${(props) => props.theme.typography.pxToRem(113 + 48 + props.$offsetHeight)});

  ${props => props.theme.breakpoints.up('sm')} {
    height: 100%;
  }
`

const StyledDisabledText = styled(Typography)`
  color: ${props => props.theme.palette.text.disabled};
`

const StyledContainer = styled('div')`
  position: relative;
  width: 100%;
  margin-top: ${({ theme }) => theme.typography.pxToRem(8)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
  }
`

const StyledGridContainer = styled(Grid)`
  width: 100%;
  z-index: 10;
  position: relative;
  
  > div {
    margin-left: ${props => props.theme.typography.pxToRem(16)};
    margin-right: ${props => props.theme.typography.pxToRem(16)};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-left: unset;
    margin-right: unset;
    padding-right: unset;

    ${scrollbarStyle}
  }
`

const StyledTabsContainer = styled(Box)`
  width: calc(100% + ${props => props.theme.typography.pxToRem(32)});
  margin-top: ${props => props.theme.typography.pxToRem(4)};
  margin-left: ${props => props.theme.typography.pxToRem(-16)};
  margin-right: ${props => props.theme.typography.pxToRem(-16)};
`

const StyledTabs = styled(Tabs)`
  margin-left: ${props => props.theme.typography.pxToRem(-20)};
  margin-right: ${props => props.theme.typography.pxToRem(-20)};
  border-bottom: 1px solid ${props => props.theme.palette.divider};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: unset;
    margin-right: unset;
  }
  
  .MuiTabs-indicator {
    width: ${props => props.theme.typography.pxToRem(32)};
  }

  .MuiButtonBase-root.MuiTab-root {
    max-width: unset;
  }
`

const StyledTab = styled(Tab)`
  width: calc(100%/3);
  font-weight: 400;
  font-size: ${props => props.theme.typography.pxToRem(16)};
  text-transform: none;
`
