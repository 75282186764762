// This component uses the react-mobile-picker library and customize the style.
// Source: https://github.com/adcentury/react-mobile-picker/

import { HTMLProps, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePickerActions, usePickerData } from './Picker'
import { useColumnData } from './PickerColumn'

interface PickerItemRenderProps {
  selected: boolean
}

export interface PickerItemProps extends Omit<HTMLProps<HTMLDivElement>, 'value' | 'children'> {
  children: ReactNode | ((renderProps: PickerItemRenderProps) => ReactNode)
  value: string
}

// eslint-disable-next-line
function isFunction(functionToCheck: any): functionToCheck is Function {
  return typeof functionToCheck === 'function'
}

export const PickerItem = ({
  style,
  children,
  value,
  ...restProps
}: PickerItemProps) => {
  const optionRef = useRef<HTMLDivElement | null>(null)
  const { itemHeight, value: pickerValue } = usePickerData('Picker.Item')
  const pickerActions = usePickerActions('Picker.Item')
  const { key, scrollerTranslate } = useColumnData('Picker.Item')
  const [initialized, setInitialized] = useState(false)

  const calculateScale = () => {
    const pickerCenterElement = document.querySelector('#pickerCenter')

    if (!optionRef.current || !pickerCenterElement || !initialized) return 'rotateX(0)'

    const itemRect = optionRef.current.getBoundingClientRect()
    const centerRect = pickerCenterElement.getBoundingClientRect()

    const itemCenter = itemRect.top + (itemRect.height / 2)
    const centerMarkerPosition = centerRect.top + (centerRect.height / 2)
    const distanceFromCenter = Math.abs(itemCenter - centerMarkerPosition)

    const maxRotationAngle = 30
    const rotation = Math.min(distanceFromCenter / 100, 1) * maxRotationAngle
  
    return `rotateX(${rotation}deg)`
  }

  useEffect(
    () => pickerActions.registerOption(key, { value, element: optionRef }),
    [key, pickerActions, value],
  )

  useEffect(() => {
    setTimeout(() => {
      setInitialized(true)
    }, 150)
  }, [])

  const itemStyle = useMemo(
    () => ({
      height: `${itemHeight}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [itemHeight],
  )

  const rotateStyle = useMemo(() => ({
    transform: calculateScale(),
  }), [scrollerTranslate, initialized])

  const handleClick = useCallback(() => {
    pickerActions.change(key, value)
  }, [pickerActions, key, value])

  return (
    <div
      style={{
        ...itemStyle,
        ...style,
        ...rotateStyle,
      }}
      ref={optionRef}
      onClick={handleClick}
      {...restProps}
    >
      {isFunction(children) ? children({ selected: pickerValue[key] === value }) : children}
    </div>
  )
}
