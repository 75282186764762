import { createEntityAdapter } from '@reduxjs/toolkit'
import { VehicleImage } from 'src/types/VehicleImage'
import { RootState } from '../store'

export const imagesAdapter = createEntityAdapter({
  selectId: (image: VehicleImage) => image.FILE,
})

export const imagesSelectors = imagesAdapter.getSelectors<RootState>(
  (state) => state.images,
)
