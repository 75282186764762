import { Grid, Paper, Typography, styled } from '@mui/material'
import { Icon } from '../Icon/Icon'
import { IconButton } from '../IconButton/IconButton'
import { Tag } from '../Tag/Tag'

export const StyledTag = styled(Tag)`
  margin-left: ${props => props.theme.typography.pxToRem(-10)};
`

export const StyledTagsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${props => props.theme.typography.pxToRem(8)};
  gap: ${props => props.theme.typography.pxToRem(16)};
`

export const StyledLikeButton = styled(IconButton)`
  position: absolute;
  z-index: 20;
  top: ${props => props.theme.typography.pxToRem(12)};
  right: ${props => props.theme.typography.pxToRem(12)};
  background-color: ${(props) => props.theme.palette.background.default};
  width: ${(props) => props.theme.typography.pxToRem(36)} !important;
  height: ${(props) => props.theme.typography.pxToRem(36)} !important;

  &:hover {
    background-color: ${(props) => props.theme.palette.custom.lightpink};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.background.default}, 0 0 0 4px ${(props) => props.theme.palette.custom.darkpink};
  }
`

export const StyledHeart = styled(Icon)`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  color: ${(props) => props.theme.palette.custom.darkpink};
`

export const StyledPaper = styled(Paper)`
  position: relative;
  padding: ${(props) => props.theme.typography.pxToRem(20)};
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid ${(props) => props.theme.palette.custom.formFieldBorder};
  transition: border ${(props) => props.theme.transitions.duration.standard}ms ease-in-out, box-shadow ${(props) => props.theme.transitions.duration.standard}ms ease-in-out;
  cursor: pointer;
  overflow: hidden;

  #cta {
    opacity: 0;
    transition: opacity ${(props) => props.theme.transitions.duration.standard}ms ease-in-out;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    box-shadow: ${(props) => props.theme.palette.custom.formFieldBoxShadow};

    #cta {
      opacity: 1;
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    & > button {
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 0.1s ease, transform 0.2s ease;
    }

    &:hover {
      & > button {
        opacity: 1;
        transform: unset;
      }
    }
  }
`

export const StyledTitle = styled(Typography)`
  font-weight: 400;
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${(props) => props.theme.typography.pxToRem(20)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: ${(props) => props.theme.typography.pxToRem(24)};
  }
`

export const StyledAvailability = styled(Typography)`
  font-weight: 400;
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: ${(props) => props.theme.typography.pxToRem(8)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: ${(props) => props.theme.typography.pxToRem(16)};
  }
`

export const StyledImageContainer = styled('div')`
  width: calc(100% + ${props => props.theme.typography.pxToRem(40)});
  position: relative;
  overflow: hidden;
  margin-top: ${(props) => props.theme.typography.pxToRem(-20)};
  margin-right: ${(props) => props.theme.typography.pxToRem(-20)};
  margin-left: ${(props) => props.theme.typography.pxToRem(-20)};
`

export const StyledCarImage = styled('img')`
  width: 100%;
`

export const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: calc(100%);
  padding: ${props => props.theme.typography.pxToRem(12)} ${props => props.theme.typography.pxToRem(20)};
  margin-left: ${props => props.theme.typography.pxToRem(-20)};
  margin-right: ${props => props.theme.typography.pxToRem(-20)};
  margin-bottom: ${props => props.theme.typography.pxToRem(-20)};
  background-color: ${props => props.theme.palette.background.paper};
  border-radius: 0 0 ${props => props.theme.typography.pxToRem(8)} ${props => props.theme.typography.pxToRem(8)};
  wrap: no-wrap;
  position: relative;
`

export const StyledIconGroup = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.typography.pxToRem(6)};
  margin-right: ${props => props.theme.typography.pxToRem(24)};

  ${props => props.theme.breakpoints.up('sm')} {
    gap: ${props => props.theme.typography.pxToRem(4)};
    margin-right: ${props => props.theme.typography.pxToRem(16)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-right: ${props => props.theme.typography.pxToRem(24)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    margin-right: ${props => props.theme.typography.pxToRem(32)};
  }
`

export const StyledIcon = styled(Icon)`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(14)} !important;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(16)} !important;
  }
`

export const StyledIconText = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(14)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledCta = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  font-weight: 500;
  white-space: nowrap;
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${props => props.theme.typography.pxToRem(20)};
  bottom: ${props => props.theme.typography.pxToRem(12)};

  .MuiSvgIcon-root {
    font-size: ${props => props.theme.typography.pxToRem(14)};
    margin-left: ${props => props.theme.typography.pxToRem(4)};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};

    .MuiSvgIcon-root {
      font-size: ${props => props.theme.typography.pxToRem(16)};
      margin-left: ${props => props.theme.typography.pxToRem(8)};
    }
  }
`