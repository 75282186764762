import { Container } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatedPage } from 'src/components/AnimatedPage/AnimatedPage'
import { ResponsibilityList } from 'src/fragments/Onboarding/ResponsibilityList'
import { useAppSelector } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'


export const Onboarding = () => {
  const user = useAppSelector(state => state.user)
  const navigate = useNavigate()

  const confirmAcceptTerms = () => {
    // localStorage.setItem('onboardingComplete', 'true')
    navigate('/explore')
  }

  useEffect(() => {
    if (user.ui_type !== 'WAIPA') {
      localStorage.setItem('onboardingComplete', 'true')
      navigate('/explore')
    }
  }, [user.ui_type])

  return user.ui_type === 'WAIPA' ? (
    <AnimatedPage>
      <Container maxWidth="md" sx={{ overflow: 'hidden', marginBottom: pxToRem(64) }}>
        <ResponsibilityList onConfirmAcceptTerms={confirmAcceptTerms} />
      </Container>
    </AnimatedPage >
  ) : null
}
