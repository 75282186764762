import { Typography, styled } from '@mui/material'
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers'
import { ComponentProps } from 'react'

export type DateTimePickerProps = Omit<ComponentProps<typeof MuiDateTimePicker>, 'label' | 'round' | 'error'> & {
  round?: boolean
  error?: string | null
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({ round, error, ...props }) => (
  <>
    <StyledDateTimePicker
      timezone="UTC"
      slotProps={{
        dialog: {
          sx: { zIndex: 9999 },
          onTouchStart: (ev) => {
            ev.stopPropagation()
          },
          onTouchEnd: (ev) => {
            ev.stopPropagation()
          },
          onTouchMove: (ev) => {
            ev.stopPropagation()
          },
        },
        popper: {
          sx: { zIndex: 9999 },
        },
      }}
      {...props}
    />
    {error && (
      <StyledErrorMessage variant="body1">
        {error}
      </StyledErrorMessage>
    )}
  </>
)

export const StyledDateTimePicker = styled(MuiDateTimePicker)`
  .MuiInputBase-root {
    box-shadow: ${(props) => props.theme.palette.custom.formFieldBoxShadow};    

    ${props => props.theme.breakpoints.up('sm')} {
      box-shadow: none;
    }
  }

  width: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
  
  .MuiOutlinedInput-root {
    height: ${(props) => props.theme.typography.pxToRem(48)};
    border-radius: ${(props) => props.theme.typography.pxToRem(8)};

    padding: 0;
  }

  .Mui-focused fieldset {
    border-color: ${(props) => props.theme.palette.text.primary};
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.background.paper}, 0 0 0 4px ${(props) => props.theme.palette.custom.button.focusedOutline};
  }

  .Mui-error.Mui-focused fieldset {
      box-shadow: 0 0 0 2px ${(props) => props.theme.palette.background.paper}, 0 0 0 4px rgba(211, 47, 47, 0.4);    
  }
  
  .Mui-disabled {
    .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.text.disabled};
    }
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }

  .MuiIconButton-root {
    margin-right: 0;
  }

`

const StyledErrorMessage = styled(Typography)`
  color: ${(props) => props.theme.palette.error.main};
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  margin-top: ${(props) => props.theme.typography.pxToRem(4)};
`
