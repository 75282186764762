import { faBell, faClose } from '@fortawesome/pro-regular-svg-icons'
import { Grid, Typography } from '@mui/material'
import { ComponentProps } from 'react'
import { pxToRem } from '../../styles/themes'
import { Icon } from '../Icon/Icon'
import { StyledCloseButton, StyledHeaderGrid, StyledNotificationContent, StyledNotificationSidebar } from './StyledNotificationSidebar'

export type NotificationSidebarProps = ComponentProps<'aside'> & {
  children: React.ReactNode
  open: boolean
  onClose: () => void
}


export const NotificationSidebar: React.FC<NotificationSidebarProps> = ({ open, onClose, children }) => {
  return (
    <StyledNotificationSidebar $open={open}>
      <StyledHeaderGrid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Icon icon={faBell} sx={{ width: pxToRem(48), marginLeft: pxToRem(-12) }} />
        </Grid>
        <Grid item>
          <Typography variant="h6">Notifications</Typography>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={onClose}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </StyledHeaderGrid>
      <StyledNotificationContent id={'notificationSidebarContent'} >
        {children}
      </StyledNotificationContent>
    </StyledNotificationSidebar>
  )
}
