import React, { ComponentProps } from 'react'
import { StyledTag, StyledTagTypography } from './StyledTag'


const tagColors = {
  red: {
    text: '#8C2822',
    background: '#F5CCCB',
  },
  green: {
    text: '#3A6B41',
    background: '#C8F5D3',
  },
  neutral: {
    text: '#5C5C5C',
    background: '#F5F5F5',
  },
}

export type TagProps = ComponentProps<'span'> & {
  color: keyof typeof tagColors
  children: React.ReactNode
  fullWidth?: boolean
}

export const Tag: React.FC<TagProps> = ({ children, color, fullWidth, ...props }) => {
  return (
    <StyledTag sx={{ width: fullWidth ? '100%' : 'auto' }} $color={tagColors[color].background} {...props}>
      <StyledTagTypography $color={tagColors[color].text} variant="body2">
        {children}
      </StyledTagTypography>
    </StyledTag>
  )
}
