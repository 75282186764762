import { Card, Grid, styled } from '@mui/material'
import { Button } from '../Button/Button'

export const StyledCard = styled(Card)`
  border-radius: ${(props) => props.theme.typography.pxToRem(12)};
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
`

export const StyledMainContent = styled('div')`
  padding: ${(props) => props.theme.typography.pxToRem(16)};
`

export const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== '$type',
}) <{ $type: 'success' | 'error' | 'warning' | 'info' | 'neutral' }>`
  padding: ${(props) => props.theme.typography.pxToRem(16)};
  color: ${(props) => props.theme.palette.common.white};

  .MuiTypography-body1 {
    font-weight: 500;
  }

  ${(props) => props.$type === 'success' && `
    background-color: ${props.theme.palette.success.main};
  `}

  ${(props) => props.$type === 'error' && `
    background-color: ${props.theme.palette.error.main};
  `}

  ${(props) => props.$type === 'warning' && `
    background-color: ${props.theme.palette.warning.main};
  `}

  ${(props) => props.$type === 'info' && `
    background-color: ${props.theme.palette.info.main};
  `}

  ${(props) => props.$type === 'neutral' && `
    background-color: ${props.theme.palette.primary.main};
  `}
`

export const StyledButtonContainer = styled(Grid)`
  margin-top: ${(props) => props.theme.typography.pxToRem(16)};
`

export const StyledPrimaryButton = styled(Button)`
  min-width: ${(props) => props.theme.typography.pxToRem(140)};
  
  .MuiButton-endIcon {
    margin-left: ${(props) => props.theme.typography.pxToRem(-16)};
    opacity: 0;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-left 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .MuiSvgIcon-root {
      font-size: ${(props) => props.theme.typography.pxToRem(16)};
    }
  }

  &:hover {
    .MuiButton-endIcon {
      opacity: 1;
      margin-left: ${(props) => props.theme.typography.pxToRem(6)};
    }
  }
`