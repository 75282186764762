import { createEntityAdapter } from '@reduxjs/toolkit'
import { Content } from 'src/types/Content'
import { RootState } from '../store'

export const ContentsAdapter = createEntityAdapter({
  selectId: (content: Content) => content.CONTENT_ID,
})

export const contentsSelector = ContentsAdapter.getSelectors<RootState>(
  (state) => state.contents,
)
