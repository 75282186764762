/* eslint-disable @typescript-eslint/no-empty-function */

import React, { ComponentProps, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLockBodyScroll } from 'react-use'

import { ModalCloseText, ModalHeaderContainer, ModalTitle } from './styledProductHeaderContent'
import {
  ModalBackground,
  ModalMainContent,
  ModalOverlayContainer,
  ModalOverlayWrapper,
} from './styledProductOverlayWrapper'

export type ModalProps = Omit<
ComponentProps<typeof ModalOverlayWrapper>,
'open' | 'animationTime' | '$fullWidth' | 'transition'
> & {
  open?: boolean
  transition?: 'opacity' | 'slide-right',
  animationDuration?: number
  portal?: string
  title?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: any
  fullWidth?: boolean
  showBackground?: boolean
  lock?: boolean; //prevent Modal from closing when open
  disabledBackdropClose?: boolean
  lockBodyScroll?: boolean
}

export const Modal: React.FC<ModalProps> = ({
  open,
  showBackground = true,
  portal = '#modal',
  title,
  onClose,
  transition = 'opacity',
  animationDuration = 250,
  children,
  lockBodyScroll,
  fullWidth,
  disabledBackdropClose,
  ...props
}) => {
  const [shouldRender, setShouldRender] = useState(open)
  const [portalElement, setPortalElement] = useState<Element | null>(null)
  const [activeElement, setActiveElement] = useState<HTMLElement>()
  const ref = useRef<HTMLDialogElement>(null)

  if (lockBodyScroll) {
    useLockBodyScroll(open)
  }
  
  useEffect(() => {
    if (open) {
      setActiveElement(document.activeElement as HTMLElement)
      setShouldRender(open)
    } else {
      const animateTimeout = setTimeout(() => {
        setShouldRender(open)
        activeElement?.focus()
      }, animationDuration - 20)

      // Fix an issue in Safari where the modal would not animate out
      if (ref && ref.current && transition === 'opacity') {
        ref.current.style.opacity = '0'
      }

      return () => {
        clearTimeout(animateTimeout)
      }
    }
  }, [open, animationDuration, activeElement, ref])

  const inner = (
    <ModalOverlayWrapper
      ref={ref}
      $fullWidth={fullWidth}
      open={open}
      animationTime={animationDuration}
      transition={transition}
      {...props}
    >
      {showBackground && <ModalBackground onClick={disabledBackdropClose ? () => { } : onClose} />}
      <ModalOverlayContainer $fullWidth={fullWidth}>
        {title && <ModalHeaderContainer>
          <ModalTitle variant="body2">{title}</ModalTitle>
          {onClose && <ModalCloseText round text onClick={onClose} >
            Close
          </ModalCloseText>}
        </ModalHeaderContainer>}
        <ModalMainContent id="__modal_main" $fullWidth={fullWidth}>
          {children}
        </ModalMainContent>
      </ModalOverlayContainer>
    </ModalOverlayWrapper >
  )

  useEffect(() => {
    const portalEl = document.querySelector(portal)

    setPortalElement(portalEl)
  }, [setPortalElement, portal])

  if (!shouldRender) {
    return null
  }

  return portalElement ? createPortal(inner, portalElement) : inner
}
