import { Accordion, AccordionDetails, AccordionSummary, Grid, Slider, Typography, styled } from '@mui/material'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { scrollbarStyle } from 'src/styles/themes'

export const StyledFilterSidebar = styled('div', {
  shouldForwardProp: (prop) => prop !== '$hide',
}) <{ $hide?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100dvw - ${(props) => props.theme.typography.pxToRem(32)});
  height: calc(100vh - ${(props) => props.theme.typography.pxToRem(65)});
  background-color: ${(props) => props.theme.palette.background.default};
  z-index: ${(props) => props.theme.zIndex.appBar + 110};
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 ${(props) => props.theme.typography.pxToRem(16)};
  transition: transform 250ms ease;
  transform: ${(props) => props.$hide ? 'translateX(110%)' : 'translateX(0)'};
  overscroll-behavior: none,
  ${scrollbarStyle}

  ${(props) => props.theme.breakpoints.up('sm')} {
    position: absolute;
    top: unset;
    left: unset;
    z-index: 0;
    width: 40%;
    margin-top: ${(props) => props.theme.typography.pxToRem(12)};
    margin-right: ${(props) => props.theme.typography.pxToRem(24)};
    transition: transform ${(props) => props.theme.transitions.duration.standard}ms ease;
    transform: ${(props) => props.$hide ? 'translateX(-110%)' : 'translateX(0)'};
    height: ${(props) => props.theme.typography.pxToRem(1100)};
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    width: 30%;
  }
`

export const StyledFilterHeader = styled(Grid)`
  position: sticky;
  top: 0;
  width: calc(100% + ${props => props.theme.typography.pxToRem(32)});
  height: ${props => props.theme.typography.pxToRem(48)};
  border-bottom: 1px solid ${props => props.theme.palette.custom.formFieldBorder};
  margin-left: ${props => props.theme.typography.pxToRem(-16)};
  margin-right: ${props => props.theme.typography.pxToRem(-16)};
  padding-left: ${props => props.theme.typography.pxToRem(16)};
  padding-right: ${props => props.theme.typography.pxToRem(16)};
  background-color: ${props => props.theme.palette.background.default};
  z-index: 1;

  ${props => props.theme.breakpoints.up('sm')} {
    display: none;
    height: 0;
    margin-top: 0;
  }
`
export const StyledToolbar = styled(Grid)`
  width: calc(100% + ${props => props.theme.typography.pxToRem(32)});
  height: ${props => props.theme.typography.pxToRem(48)};
  border-bottom: 1px solid ${props => props.theme.palette.custom.formFieldBorder};
  margin-left: ${props => props.theme.typography.pxToRem(-16)};
  margin-right: ${props => props.theme.typography.pxToRem(-16)};
  padding-left: ${props => props.theme.typography.pxToRem(16)};
  padding-right: ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('sm')} {
    display: none;
    height: 0;
    margin-top: 0;
  }
`

export const StyledFilterTypography = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  font-weight: 500;
`

export const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  box-shadow: none;
  border-radius: 0 !important;

  &:before {
    display: none;
  }
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding-left: 0;
  padding-right: 0;
`

export const StyledAngleIcon = styled(Icon)`
  color: ${props => props.theme.palette.text.primary};
  width: ${props => props.theme.typography.pxToRem(24)};
  height: ${props => props.theme.typography.pxToRem(24)};
`

export const StyledGroupTypography = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 300;
`

export const StyledOptionTypography = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-bottom: ${props => props.theme.typography.pxToRem(4)};
  font-weight: 500;
`

export const StyledRangeSlider = styled(Slider)`
  margin-left: ${props => props.theme.typography.pxToRem(10)};
  width: calc(100% - ${props => props.theme.typography.pxToRem(24)});

  .MuiSlider-markLabel {
    font-size: ${props => props.theme.typography.pxToRem(14)};
    margin-left: ${props => props.theme.typography.pxToRem(14)};
  }

  .MuiSlider-markLabel[data-index="1"] {
    padding-right: ${props => props.theme.typography.pxToRem(64)};
  }
`

export const StyledFilterButton = styled(Button)`
  margin-top: ${props => props.theme.typography.pxToRem(16)};
  margin-bottom: ${props => props.theme.typography.pxToRem(56)};

  ${props => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`