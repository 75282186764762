import { Container } from '@mui/material'
import { AnimatedPage } from 'src/components/AnimatedPage/AnimatedPage'
import { MyBookings } from 'src/fragments/Bookings/MyBookings'
import { useLayoutContext } from 'src/fragments/Layout/LayoutContext'


export const Bookings = () => {
  const { setSelectedBookingId } = useLayoutContext()

  return (
    <AnimatedPage>
      <Container maxWidth="lg" sx={{ overflow: 'hidden' }}>
        <MyBookings setActiveBookingId={setSelectedBookingId} />
      </Container>
    </AnimatedPage >
  )
}
