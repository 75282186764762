import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Vehicle } from 'src/types/Vehicle'
import { updateVehicle } from '../reducers/vehicles'

export const unlikeVehicle = createAsyncThunk('vehicle/unlike', async (vehicle: Vehicle, { dispatch }) => {
  // Optimistic UI update
  dispatch(updateVehicle({
    ...vehicle,
    isLiked: false,
  }))
  
  CarshareApiService.post<Vehicle>('deleteFavouriteVehicle', {
    vehicle_guid: vehicle.vehicle_guid,
    favourite_guid: vehicle.favourite_guid,
  }).then((res) => {
    if (res.status === 'OK' && res.results !== null) {
      dispatch(updateVehicle(res.results[0]))
    }
  })
})