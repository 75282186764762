import { styled } from '@mui/material'
import { ComponentProps } from 'react'
import { Link, useLocation } from 'react-router-dom'

export type NavLinkProps = ComponentProps<typeof Link> & {
  children?: React.ReactNode
}

export const NavLink: React.FC<NavLinkProps> = ({ children, ...props }) => {
  const location = useLocation()
  const locationPaths = location.pathname.split('/')
  const isEqual = `/${locationPaths[1]}` === props.to

  return (
    <StyledLink {...props} $active={isEqual}>
      {children}
    </StyledLink>
  )
}


const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== '$active',
}) <{ $active?: boolean }>`
  position: relative;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  font-weight: 300;
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
  transition: color 150ms ease-in-out;
  &:not(:last-child) {
    margin-right: ${(props) => props.theme.typography.pxToRem(32)};
  }

  &:after {
    content: "";
    position: absolute;
    left: -4px;
    right: -4px;
    bottom: -5px;
    height: 2px;
    background-color: ${(props) => props.theme.palette.custom.button.primaryBackgroundHover};
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  &:hover {
    cursor: pointer;

    &:after {
      opacity: 1;
    }
  }

  ${(props) => props.$active && `
    &:after {
      opacity: 1;
    }
  `}

  ${props => props.theme.breakpoints.up('md')} {
    &:not(:last-child) {
      margin-right: ${(props) => props.theme.typography.pxToRem(64)};
    }
  }
`