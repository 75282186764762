import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Booking } from 'src/types/Booking'
import { bookingsAdapter } from '../adapters/bookings'

export const initialState = bookingsAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    addMultipleBookings: (state, action: PayloadAction<Booking[]>) => {
      bookingsAdapter.upsertMany(state, action.payload.map((booking) => {
        return {
          id: booking.BOOKING_ID,
          ...booking,
        }
      }))
    },
    replaceAllBookings: (state, action: PayloadAction<Booking[]>) => {
      bookingsAdapter.setAll(state, action.payload.map((booking) => {
        return {
          id: booking.BOOKING_ID,
          ...booking,
        }
      }))
    },
    removeAllBookings: (state) => {
      bookingsAdapter.removeAll(state)
    },
  },
})

export const { addMultipleBookings, removeAllBookings, replaceAllBookings } = actions