import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Booking } from 'src/types/Booking'
import { replaceAllBookings } from '../reducers/bookings'

export const setupBookings = createAsyncThunk('bookings/setup', async (_, { dispatch }) => {
  // Retrieve Bookings
  const bookings = await CarshareApiService.post<Booking>('getBookings', {
    limit: 10000,
  })

  if (bookings.results) {
    dispatch(replaceAllBookings(bookings.results))
  }
})