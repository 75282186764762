import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Content } from 'src/types/Content'
import { replaceAllContents } from '../reducers/contents'

export const setupContents = createAsyncThunk('contents/setup', async (_, { dispatch }) => {
  // Retrieve Contents
  const contents = await CarshareApiService.post<Content>('getContents', {
    limit: 10000,
  })

  if (contents.results) {
    const decodedContents = contents.results.map(content => ({
      ...content,
      content_data: atob(content.content_data),
    }))

    dispatch(replaceAllContents(decodedContents))
  }
})