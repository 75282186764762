import { AppBar, Link, Toolbar, styled } from '@mui/material'
import { ComponentProps } from 'react'

export type NavHeaderProps = ComponentProps<typeof AppBar> & {
  children?: React.ReactNode
}

export const NavHeader: React.FC<NavHeaderProps> = ({ children, ...props }) => {
  return (
    <StyledAppBar elevation={0} {...props}>
      <StyledToolbar variant="dense">
        {children}
      </StyledToolbar>
    </StyledAppBar>
  )
}

export const StyledAppBar = styled(AppBar)`
  width: 100%;
`

export const StyledToolbar = styled(Toolbar)`
  background-color: ${(props) => props.theme.palette.primary.dark};

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding-left: ${(props) => props.theme.typography.pxToRem(36)};
    padding-right: ${(props) => props.theme.typography.pxToRem(36)};
  }
`

export const StyledLogoContainer = styled(Link)`
  margin: 0;
  display: flex;
  position: relative;
`

export const StyledLogo = styled('img')`
  height: ${(props) => props.theme.typography.pxToRem(28)};
  margin: ${(props) => props.theme.typography.pxToRem(10)} auto;
`