
const carshareAuthResources = {
  login: '/api/auth/login/',
  logout: '/api/auth/logout/',
  session: '/api/auth/session/',
  resetPassword: '/api/common/reset_pw/replace/',
  changePassword: '/api/common/reset_pw/update/',

  getAccount: '/api/common/accounts/',
  updateAccount: '/api/common/accounts/update/',
  updateAccountPassword: '/api/common/accounts/Change/password/',
}

const carshareCustomerResources = {
  authLogin: '/auth/login/',
  authLogout: '/auth/logout/',
  authSession: '/auth/session/',

  getCustomerDetails: '/api/v1/carshare/customers/',

  getFavouriteVehicles: '/api/v1/carshare/vehicles/favourites/',
  addFavouriteVehicle: '/api/v1/carshare/vehicles/favourites/create/',
  deleteFavouriteVehicle: '/api/v1/carshare/vehicles/favourites/delete/',

  getVariables: '/api/v1/settings/variables/',
  getMakesLogos: '/api/v1/carshare/makes/logos/',
  getVehicleMakes: '/api/v1/carshare/makes/',
  getLocation: '/api/v1/carshare/locations/',

  getBookings: '/api/v1/carshare/vehicles/bookings/',
  createBooking: '/api/v1/carshare/vehicles/bookings/create/',
  updateBooking: '/api/v1/carshare/vehicles/bookings/update/',
  cancelBooking: '/api/v1/carshare/vehicles/bookings/cancel/',
  startBooking: '/api/v1/carshare/vehicles/bookings/start/',
  completeBooking: '/api/v1/carshare/vehicles/bookings/complete/',

  getPassengers: '/api/v1/carshare/bookings/passengers/',

  createOneSignal: '/api/v1/carshare/customers/onesignal/create/',

  getVehicles: '/api/v1/carshare/vehicles/search/',
  getVehicleAvailability: '/api/v1/carshare/vehicles/availability/',
  getVehicleImages: '/api/v1/carshare/vehicles/images/',
  getCustomerNotifications: '/api/v1/carshare/customers/notifications/',
  dismissNotification: '/api/v1/carshare/customers/notifications/read/',

  getSelects: '/api/multiselect/',
  getContents: '/api/settings/content/',
}

export const carshareResources = {
  ...carshareAuthResources,
  ...carshareCustomerResources,
}
