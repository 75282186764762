import { faChevronLeft, faClose } from '@fortawesome/pro-regular-svg-icons'
import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useContext } from 'react'

import { pxToRem } from 'src/styles/themes'
import { Icon } from '../Icon/Icon'
import { IconButton } from '../IconButton/IconButton'
import { Link } from '../Link/Link'
import { MobileSliderContext } from './MobileSliderContext'

export type MobileSliderTitleProps = {
  children: React.ReactNode
  onClose: () => void
}

export const StyledBrowserHeaderTypography = styled(Typography)`
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(16)};
`

export const StyledCloseIcon = styled(IconButton)`
  position: absolute;
  right: 0;
  width: 44px;
  height: 44px;
  border: none;
  color: ${props => props.theme.palette.text.primary};

  &:hover {
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
  }
`

export const StyledListItemGrid = styled(Grid)`

`

export const StyledMobileSliderTitle = styled('li')`
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  height: ${props => props.theme.typography.pxToRem(54)};
  background-color: ${props => props.theme.palette.background.paper};
  z-index: 1;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
`

export const MobileSliderTitle: React.FC<MobileSliderTitleProps> = ({ onClose, children }) => {
  const menuContext = useContext(MobileSliderContext)

  const onNavigateBack = () => {
    if (menuContext.menuStack.length === 1 ) return 

    const [level, idx] = menuContext.menuStack[menuContext.menuStack.length - 1] || [0, 0]

    menuContext.setMenuClose(level, idx)
  }

  return (
    <StyledMobileSliderTitle>
      <StyledBackLink $show={menuContext.menuStack.length > 1 } onClick={onNavigateBack}>
        <Icon icon={faChevronLeft} sx={{ width: pxToRem(16), height: pxToRem(16), marginRight: pxToRem(4) }}/>
        Back
      </StyledBackLink>
      <StyledBrowserHeaderTypography variant="body1">
        {children}
      </StyledBrowserHeaderTypography>
      <StyledCloseIcon onClick={onClose}>
        <Icon icon={faClose} width={22} height={22} />
      </StyledCloseIcon>
    </StyledMobileSliderTitle>
  )
}

const StyledBackLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== '$show',
}) <{ $show?: boolean }>`
  position: absolute;
  text-decoration: none;
  left: ${props => props.theme.typography.pxToRem(16)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  font-weight: 500;
  opacity: ${props => props.$show ? 1 : 0};
  visibility: ${props => props.$show ? 'visible' : 'hidden'};
  transition: opacity 150ms ease-in-out;
  display: flex;
  align-items: center;
`