import { createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Vehicle } from 'src/types/Vehicle'
import { VehicleImage } from 'src/types/VehicleImage'
import { replaceAllImages } from '../reducers/images'
import { replaceAllVehicles } from '../reducers/vehicles'

type SetupPayload = {
  date_from?: string
  date_to?: string
}

export const setupVehicles = createAsyncThunk('vehicles/setup', async (payload: SetupPayload, { dispatch }) => {
  const [vehicles, vehicleImages, favourites] = await Promise.all([
    CarshareApiService.post<Vehicle>('getVehicles', { ...payload }),
    CarshareApiService.post<VehicleImage>('getVehicleImages', {}),
    CarshareApiService.post<Vehicle>('getFavouriteVehicles', {}),
  ])

  let updatedVehicles = vehicles.results

  if (favourites.status === 'OK' && favourites.results !== null) {
    updatedVehicles = vehicles.results.map((vehicle) => {
      const favourite = favourites.results.find((fav) => fav.vehicle_guid === vehicle.vehicle_guid)

      return {
        ...vehicle,
        ...favourite,
        isLiked: favourite?.favourite_guid !== '' && (
          favourite?.favourite_deleted === '0000-00-00 00:00:00' || dayjs(favourite?.favourite_deleted).isBefore(dayjs(favourite?.favourite_created))
        ),
      }
    })
  }

  if (vehicles.results && vehicleImages.results) {
    dispatch(replaceAllVehicles(updatedVehicles))
    dispatch(replaceAllImages(vehicleImages.results))
  }
})