import { faHeart as faHeartLight } from '@fortawesome/pro-light-svg-icons'
import { faChevronCircleRight, faGasPump, faHeart as faHeartSolid, faSuitcase, faUserGroup } from '@fortawesome/pro-solid-svg-icons'
import { Grid, Paper } from '@mui/material'
import React, { ComponentProps } from 'react'
import { Vehicle } from 'src/types/Vehicle'
import { VehicleImage } from 'src/types/VehicleImage'
import { Icon } from '../Icon/Icon'
import { StyledVehicleText } from '../UpcomingBookingCard/StyledUpcomingBookingCard'
import {
  StyledAvailability, StyledCarImage, StyledContent, StyledCta, StyledHeart,
  StyledIcon,
  StyledIconGroup, StyledIconText, StyledImageContainer, StyledLikeButton, StyledPaper,
  StyledTag, StyledTagsContainer, StyledTitle,
} from './StyledVehicleCard'

export type VehicleCardProps = ComponentProps<typeof Paper> & {
  onClick?: () => void
  onLike?: () => void
  vehicle: Vehicle
  image: VehicleImage
}

export const VehicleCard: React.FC<VehicleCardProps> = ({ onClick, onLike, vehicle, image, ...props }) => {
  const getFuelInitials = (fuel: string) => {
    const lowerCaseName = fuel.toLowerCase()

    if (lowerCaseName.includes('petrol')) {
      return 'P'
    }

    if (lowerCaseName.includes('diesel')) {
      return 'D'
    }

    if (lowerCaseName.includes('ev')) {
      return 'EV'
    }

    return '?'
  }

  const getTransmissionInitials = (transimission: string) => {
    const lowerCaseCodes = transimission.toLowerCase()

    if (lowerCaseCodes.includes('manual') && lowerCaseCodes.includes('auto')) {
      return 'M/A'
    }

    if (lowerCaseCodes.includes('manual')) {
      return 'M'
    }

    if (lowerCaseCodes.includes('auto')) {
      return 'A'
    }

    return 'n/a'
  }

  const onLikeClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (onLike) {
      onLike()
    }
  }

  return (
    <StyledPaper elevation={0} onClick={onClick} {...props}>
      <StyledImageContainer>
        <StyledCarImage src={image ? image.file_image_src_data : '/images/car-placeholder.svg'}>
        </StyledCarImage>
      </StyledImageContainer>
      <StyledContent>
        <StyledTitle variant="h2">
          {vehicle?.make_name} {vehicle?.model_name}
        </StyledTitle>
        <StyledAvailability variant="body1">
          {vehicle?.vehicle_rego_number}
        </StyledAvailability>
        <Grid container alignItems={'center'}>
          {vehicle?.vehicle_nr_seats && <StyledIconGroup item>
            <StyledIcon icon={faUserGroup} />
            <StyledIconText variant="body1">
              {vehicle.vehicle_nr_seats}
            </StyledIconText>
          </StyledIconGroup>}
          {vehicle?.vehicle_nr_luggage && <StyledIconGroup item>
            <StyledIcon icon={faSuitcase} />
            <StyledIconText variant="body1">
              {vehicle?.vehicle_nr_luggage}
            </StyledIconText>
          </StyledIconGroup>}
          {vehicle?.vehicle_fuel && <StyledIconGroup item>
            <StyledIcon icon={faGasPump} />
            <StyledIconText variant="body1">
              {getFuelInitials(vehicle.vehicle_fuel)}
            </StyledIconText>
          </StyledIconGroup>}
          {vehicle?.vehicle_fuel && <StyledIconGroup item>
            <img src={'/images/transmission.svg'} />
            <StyledIconText variant="body1">
              {getTransmissionInitials(vehicle.vehicle_transmission)}
            </StyledIconText>
          </StyledIconGroup>}
        </Grid>
        {vehicle?.vehicle_instructions && (
          <StyledVehicleText variant="body1">
            {vehicle.vehicle_instructions}
          </StyledVehicleText>
        )}
        {vehicle?.vehicle_attribute_names && (
          <StyledTagsContainer>
            {vehicle.vehicle_attribute_names.split(',').filter((attr) => attr.trim() !== '').map((attr) => (
              <StyledTag key={attr} color={'neutral'}>
                {attr}
              </StyledTag>
            ))}
          </StyledTagsContainer>
        )}
        <StyledCta id="cta">
          Book
          <Icon icon={faChevronCircleRight} />
        </StyledCta>
      </StyledContent>
      {onLike && <StyledLikeButton onClick={onLikeClick}>
        <StyledHeart icon={vehicle?.isLiked ? faHeartSolid : faHeartLight} />
      </StyledLikeButton>}
    </StyledPaper>
  )
}
