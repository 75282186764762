
import { Alert, Divider, Grid, Link as MuiLink, Typography, styled } from '@mui/material'
import { Breadcrumbs } from 'src/components/Breadcrumbs/Breadcrumbs'
import { Icon } from 'src/components/Icon/Icon'
import { IconButton } from 'src/components/IconButton/IconButton'

export const StyledCheveronButton = styled(IconButton)`
  margin-left: ${props => props.theme.typography.pxToRem(-10)};

  &:focus {
    box-shadow: none;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledContainer = styled('div')`
  margin-bottom: ${props => props.theme.typography.pxToRem(128)};
  position: relative;

  .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    width: 100%;
    
    .MuiContainer-root {
      padding-left: ${props => props.theme.typography.pxToRem(16)} !important;
      padding-right: ${props => props.theme.typography.pxToRem(16)} !important;
    }
  }
`

export const StyledHeader = styled(Grid)`
  width: calc(100% + ${props => props.theme.typography.pxToRem(40)});
  height: ${props => props.theme.typography.pxToRem(48)};
  border-bottom: 1px solid ${props => props.theme.palette.custom.formFieldBorder};
  margin-left: ${props => props.theme.typography.pxToRem(-20)};
  margin-right: ${props => props.theme.typography.pxToRem(-20)};
  padding-left: ${props => props.theme.typography.pxToRem(20)};
  padding-right: ${props => props.theme.typography.pxToRem(20)};
  background-color: ${props => props.theme.palette.background.paper};
  position: sticky;
  top: 0;
  z-index: 1;

  ${props => props.theme.breakpoints.up('sm')} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    border-bottom: none;
    background-color: transparent;
  }
`

export const StyledHeaderTypography = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  font-weight: 500;

  ${props => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`

export const StyledIconButton = styled(IconButton)`
  margin-right: ${props => props.theme.typography.pxToRem(-8)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-right: ${props => props.theme.typography.pxToRem(16)};
  }
`
export const StyledGridFullWidth = styled(Grid)`
  width: 100%;

  ${props => props.theme.breakpoints.up('sm')} {
    width: unset;
  }
`

export const StyledImage = styled('img')`
  width: calc(100% + ${props => props.theme.typography.pxToRem(40)});
  margin-left: ${props => props.theme.typography.pxToRem(-20)};

  ${props => props.theme.breakpoints.up('sm')} {
    width: ${props => props.theme.typography.pxToRem(360)};
  }
`

export const StyledVehicleRego = styled(Typography)`
  margin-top: ${props => props.theme.typography.pxToRem(8)};
  font-size: ${props => props.theme.typography.pxToRem(18)};
  font-weight: 300;
`

export const StyledMakeModel = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  font-weight: 300;
`
export const StyledDivider = styled(Divider)`
  padding-top: ${props => props.theme.typography.pxToRem(24)};
  left: 0;
  right: 0;
  margin-left: ${props => props.theme.typography.pxToRem(-20)};
  margin-right: ${props => props.theme.typography.pxToRem(-20)};

  ${props => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`

export const StyledContent = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${props => props.theme.palette.background.paper};
  border-radius: 0 0 ${props => props.theme.typography.pxToRem(8)} ${props => props.theme.typography.pxToRem(8)};
  wrap: no-wrap;
  position: relative;
  margin-top: ${props => props.theme.typography.pxToRem(20)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: ${props => props.theme.typography.pxToRem(32)};
    margin-top: 0;
  }
`

export const StyledIconGroup = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.typography.pxToRem(6)};
  margin-right: ${props => props.theme.typography.pxToRem(24)};

  ${props => props.theme.breakpoints.up('sm')} {
    gap: ${props => props.theme.typography.pxToRem(4)};
    margin-right: ${props => props.theme.typography.pxToRem(16)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-right: ${props => props.theme.typography.pxToRem(24)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    margin-right: ${props => props.theme.typography.pxToRem(32)};
  }
`

export const StyledIcon = styled(Icon)`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(14)} !important;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(16)} !important;
  }
`

export const StyledIconText = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(14)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledPrimaryTypography = styled(Typography)`
  font-weight: 400;
  font-size: ${props => props.theme.typography.pxToRem(16)};
  margin-top: ${props => props.theme.typography.pxToRem(20)};
`

export const StyledSecondaryTypography = styled(Typography)`
  font-weight: 400;
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-top: ${props => props.theme.typography.pxToRem(2)};
`

export const StyledAlert = styled(Alert)`
  margin-left: ${props => props.theme.typography.pxToRem(-20)};
  margin-right: ${props => props.theme.typography.pxToRem(-20)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: unset;
    margin-right: unset;
    margin-bottom: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledHelpIcon = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: ${props => props.theme.typography.pxToRem(-16)};
  margin-right: ${props => props.theme.typography.pxToRem(-12)};

  ${props => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`

export const StyledSliderContent = styled('div')`
  padding: 0 ${(props) => props.theme.typography.pxToRem(16)};
`

export const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 300;
  margin-top: ${props => props.theme.typography.pxToRem(8)};
  margin-bottom: ${props => props.theme.typography.pxToRem(8)};

  ${props => props.theme.breakpoints.up('md')} {
    display: block;
    font-size: ${props => props.theme.typography.pxToRem(28)};
    margin-top: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledTitleDescription = styled(Typography)`
  display: none;
  font-size: ${props => props.theme.typography.pxToRem(14)};
  font-weight: 400;
  margin-bottom: ${props => props.theme.typography.pxToRem(8)};

  ${props => props.theme.breakpoints.up('md')} {
    display: block;
    margin-bottom: ${props => props.theme.typography.pxToRem(16)};
  } 
`
export const StyledBreadCrumb = styled(Breadcrumbs)`

  ${props => props.theme.breakpoints.up('sm')} {
    display: block;
  }
`

export const StyledBreadCrumbTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: 300;
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
`

export const StyledBreadCrumbMuiLink = styled(MuiLink)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-weight: 300;
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.palette.text.primary};
  }
`
