import { createSlice } from '@reduxjs/toolkit'
import { User } from 'src/types/User'
import { setupUser } from '../thunks/setupUser'

export const initialState: User = {
  email: null,
  given_name: '',
  status: '',
  sessionid: null,
  accessid: null,
  idtype: '',
  group_code: '',
}

export const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => builder
    .addCase(setupUser.fulfilled, (state, action) => {
      if (action) {
        state.email = action.payload.email
        state.given_name = action.payload.given_name
        state.status = action.payload.status
        state.sessionid = action.payload.sessionid
        state.accessid = action.payload.accessid
        state.idtype = action.payload.idtype
        state.group_code = action.payload.group_code
        state.ui_type = action.payload.ui_type
      }
    }),
})
