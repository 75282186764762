import { styled } from '@mui/material'
import { ComponentProps } from 'react'

export type TabPanelProps = ComponentProps<typeof StyledTabPanelContainer> & {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...props }) => {
  return (
    <StyledTabPanelContainer
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {children}
    </StyledTabPanelContainer>
  )
}

const StyledTabPanelContainer = styled('div')`
  > div {
    padding-bottom: 16px;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    height: 100%;
  }
`
