import { Container, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatedPage } from 'src/components/AnimatedPage/AnimatedPage'
import { Button } from 'src/components/Button/Button'
import { MobileSlider } from 'src/components/MobileSlider/MobileSlider'
import { Modal } from 'src/components/Modal/Modal'
import { BookingForm } from 'src/fragments/BookingForm/BookingForm'
import { VehicleCardFragment } from 'src/fragments/Explore/VehicleCardFragment'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppSelector } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'
import { Vehicle } from 'src/types/Vehicle'

export const Favourites = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const favouriteVehicles = useAppSelector((state) => vehiclesSelectors.selectAll(state)).filter((vehicle) => {
    return vehicle.isLiked
  })
  const [activeVehicleId, setActiveVehicleId] = useState<Vehicle['VEHICLE_ID'] | null>(null)

  return (
    <AnimatedPage>
      <Container maxWidth="lg" sx={{ overflow: 'hidden' }}>
        <StyledVehicleCards container justifyContent="flex-start" sx={{ marginTop: pxToRem(24), width: '100%' }}>
          {favouriteVehicles.map((vehicle, idx) => (
            <StyledVehicleCardGrid item key={idx} xs={12} sm={6} md={4}>
              <VehicleCardFragment
                vehicleId={vehicle.VEHICLE_ID}
                onClick={() => {
                  setActiveVehicleId(vehicle.VEHICLE_ID)
                }}
              />
            </StyledVehicleCardGrid>
          ))}
          {favouriteVehicles && favouriteVehicles.length === 0 && (
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <StyledTypography variant="body1" align="center">
                No Favourite Vehicles
              </StyledTypography>
              <Button
                onClick={() => {
                  navigate('/explore')
                }}
                sx={{
                  marginTop: pxToRem(24),
                  width: 'fit-content',
                }}
              >
                Browse Vehicles
              </Button>
            </Grid>
          )}
        </StyledVehicleCards>

        {isMobile &&
          <MobileSlider
            wrapper
            portal="#slider"
            isOpen={Boolean(activeVehicleId)}
            onClose={() => {
              setActiveVehicleId(null)
            }}
            initialTitle="Add Booking"
          >
            <MobileSlider navigation level={0} idx={0}>
              <StyledSliderContent>
                <BookingForm
                  vehicleId={activeVehicleId || ''}
                  isEditForm={false}
                  isMobileSlider={true}
                  onFormSubmitSuccess={() => {
                    setActiveVehicleId(null)
                  }}
                />
              </StyledSliderContent>
            </MobileSlider>
          </MobileSlider>
        }

        {!isMobile && <Modal
          title={'Add Booking'}
          open={Boolean(activeVehicleId)}
          onClose={() => {
            setActiveVehicleId(null)
          }}
        >
          <BookingForm
            vehicleId={activeVehicleId || ''}
            isEditForm={false}
            onFormSubmitSuccess={() => {
              setActiveVehicleId(null)
            }}
          />
        </Modal>}
      </Container>
    </AnimatedPage>
  )
}

const StyledVehicleCards = styled(Grid)`
  background-color: ${(props) => props.theme.palette.background.default};
  transition: margin-left ${(props) => props.theme.transitions.duration.standard}ms ease;
  width: 100%;
  padding-bottom: ${(props) => props.theme.typography.pxToRem(65)};
`

const StyledVehicleCardGrid = styled(Grid)`
  padding-bottom: ${(props) => props.theme.typography.pxToRem(24)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding-right: ${(props) => props.theme.typography.pxToRem(24)};
  }
`

const StyledTypography = styled(Typography)`
  font-weight: 300;
  font-size: ${(props) => props.theme.typography.pxToRem(24)};
  color: ${(props) => props.theme.palette.text.disabled};
`

const StyledSliderContent = styled('div')`
  padding: 0 ${(props) => props.theme.typography.pxToRem(16)};
`
