import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { FilterOptions } from 'src/types/FilterOptions'
import { Settings } from 'src/types/Settings'

export const initialFilterState: FilterOptions = {
  fromDate: null,
  toDate: null,
  pickupHour: 0,
  pickupMinute: 0,
  dropoffHour: 0,
  dropoffMinute: 0,
  pickupLocation: null,
  make: null,
  range: 150,
  seats: '1',
  luggages: '1',
  fuelType: null,
  transmission: null,
  attributes: [],
  features: [],
}

export const initialState: Settings = {
  navSidebarOpen: false,
  filterOptions: initialFilterState,
}

export const { reducer, actions } = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setNavSidebarOpen: (state, action: PayloadAction<Settings['navSidebarOpen']>) => {
      state.navSidebarOpen = action.payload
    },
    setFilterOptions: (state, action: PayloadAction<Partial<Settings['filterOptions']>>) => {
      state.filterOptions = { ...state.filterOptions, ...action.payload }
    },
    resetFilterOptions: (state) => {
      state.filterOptions = initialFilterState
    },
  },
})

export const { setNavSidebarOpen, setFilterOptions, resetFilterOptions } = actions