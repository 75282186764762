import { styled, Typography } from '@mui/material'

export const StyledTag = styled('span', { shouldForwardProp: (prop) => prop !== '$color' }) <{ $color: string }>`
  display: inline-flex;
  padding: ${props => props.theme.typography.pxToRem(6)} ${props => props.theme.typography.pxToRem(10)};
  border-radius: ${props => props.theme.typography.pxToRem(4)};
  background-color: ${props => props.$color};
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`

export const StyledTagTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== '$color' }) <{ $color: string }>`
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(14)};
  color: ${props => props.$color};
  line-height: 1;
`