import { Paper, Typography, styled } from '@mui/material'
import { Icon } from '../Icon/Icon'

export const StyledPaper = styled(Paper)`
  position: relative;
  padding: ${(props) => props.theme.typography.pxToRem(20)} ${(props) => props.theme.typography.pxToRem(16)};
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
  background-color: ${(props) => props.theme.palette.background.paper};
  margin-left: ${(props) => props.theme.typography.pxToRem(16)};
  border: 1px solid ${(props) => props.theme.palette.custom.formFieldBorder};
  transition: border ${(props) => props.theme.transitions.duration.standard}ms ease-in-out, box-shadow ${(props) => props.theme.transitions.duration.standard}ms ease-in-out;
  cursor: pointer;
  min-width: 76dvw;

  #cta {
    opacity: 0;
    transition: opacity ${(props) => props.theme.transitions.duration.standard}ms ease-in-out;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    box-shadow: ${(props) => props.theme.palette.custom.formFieldBoxShadow};

    #cta {
      opacity: 1;
    }
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.typography.pxToRem(20)};
    margin-left: unset;
  }
`

export const StyledTitle = styled(Typography)`
  font-weight: 500;
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: ${(props) => props.theme.typography.pxToRem(18)};
  }
`

export const StyledDescription = styled(Typography)`
  font-weight: 400;
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  color: ${(props) => props.theme.palette.text.secondary};
  margin-top: ${(props) => props.theme.typography.pxToRem(2)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-top: ${(props) => props.theme.typography.pxToRem(4)};
    font-size: ${(props) => props.theme.typography.pxToRem(16)};
  }
`

export const StyledVehicleText = styled(Typography)`
  font-weight: 400;
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  margin-top: ${(props) => props.theme.typography.pxToRem(8)};
`

export const StyledCheveron = styled(Icon)`
  position: absolute;
  right: ${(props) => props.theme.typography.pxToRem(16)};
  bottom: ${(props) => props.theme.typography.pxToRem(20)};
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  color: ${(props) => props.theme.palette.text.primary};
`

export const StyledStatus = styled('strong', { shouldForwardProp: (prop) => prop !== '$type' }) <{
  $type: 'success' | 'error' | 'warning' | 'info'
}>`
  font-weight: 500;
  ${(props) => props.$type === 'success' && `
    color: ${props.theme.palette.primary.main};
  `}

  ${(props) => props.$type === 'error' && `
    color: ${props.theme.palette.error.main};
  `}

  ${(props) => props.$type === 'warning' && `
    color: ${props.theme.palette.warning.main};
  `}

  ${(props) => props.$type === 'info' && `
    color: ${props.theme.palette.info.main};
  `}
`