/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)

const initializeDayJs = () => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(quarterOfYear)
}

export default initializeDayJs