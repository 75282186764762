import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Vehicle } from 'src/types/Vehicle'
import { vehiclesAdapter } from '../adapters/vehicles'

export const initialState = vehiclesAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    addMultipleVehicles: (state, action: PayloadAction<Vehicle[]>) => {
      vehiclesAdapter.upsertMany(state, action.payload)
    },
    replaceAllVehicles: (state, action: PayloadAction<Vehicle[]>) => {
      vehiclesAdapter.setAll(state, action.payload)
    },
    updateVehicle: (state, action: PayloadAction<Vehicle>) => {
      vehiclesAdapter.upsertOne(state, action.payload)
    },
  },
})


export const { addMultipleVehicles, replaceAllVehicles, updateVehicle } = actions