
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { useDrawerSwipe } from 'src/hooks/useDrawerSwipe'
import { MobileSliderContext } from './MobileSliderContext'
import { StyledListContainer, StyledSliderNavigation } from './StyledNavigator'

export type MobileSliderNavigationProps = {
  level: number
  idx: number
  animationDuration?: number
  children: React.ReactNode
  title?: string
}

export const MobileSliderNavigation: React.FC<MobileSliderNavigationProps> = ({ level, idx, children, animationDuration = 150, title, ...props }) => {
  const [isAnimateOut, setIsAnimateOut] = useState(false)
  const menuContext = useContext(MobileSliderContext)
  const currentIndex = menuContext.getItemIndex(level, idx)
  const isGlobalOpen = menuContext.menuState[0]
  const isOpen = menuContext.menuState[currentIndex]
  const { portal } = menuContext

  const [shouldRender, setShouldRender] = useState(isOpen)
  const [activeElement, setActiveElement] = useState<HTMLElement>()
  
  //Swipe to close
  const { style, ...swipeHandlers } = useDrawerSwipe({
    disabled: level <= 0,
    onSwipedRight: () => {
      if (level !== 0 && idx !== 0) {
        menuContext.setMenuClose(level, idx)
      }
    },
    animationDuration: animationDuration,
  })

  useEffect(() => {
    if (menuContext.menuStack.length <= 1) {
      setIsAnimateOut(false)

      return
    }

    const [lastLevel, lastIdx] = menuContext.menuStack[menuContext.menuStack.length - 2]

    if (lastLevel === level && lastIdx === idx) {
      setIsAnimateOut(true)
    }
  }, [menuContext.menuStack])

  // Add navigation title to title stack if exists
  useEffect(() => {
    if (isOpen) {
      menuContext.setTitleStack(prev => [...prev, title ?? prev[prev.length - 1]])
    }
  }, [isOpen])

  // Allow for animation when opening and closing before completely unmounting
  useEffect(() => {
    if (isOpen) {
      setActiveElement(document.activeElement as HTMLElement)
      setShouldRender(isOpen)
    } else {
      const animateTimeout = setTimeout(() => {
        setShouldRender(isOpen)
        activeElement?.focus()
      }, animationDuration - 30)

      return () => {
        clearTimeout(animateTimeout)
      }
    }
  }, [isOpen, animationDuration, activeElement])

  if (!shouldRender) {
    return null
  }

  const navigationSlider = (
    <StyledSliderNavigation
      {...props}
      {...swipeHandlers}
      onClick={(ev) => {ev.stopPropagation()}}
      $isGlobalOpen={isGlobalOpen}
      $isOpen={isOpen}
      $level={level}
      $transform={style?.transform}
      $animationTime={animationDuration}
      data-scroll-lock-scrollable
      style={isOpen && !isAnimateOut ? style : {}}
    >
      <StyledListContainer>
        {children}
      </StyledListContainer>
    </StyledSliderNavigation>
  )

  return portal ? (
    createPortal(navigationSlider, portal)
  ) : (
    navigationSlider
  )
}
