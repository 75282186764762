import { createEntityAdapter } from '@reduxjs/toolkit'
import { Location } from 'src/types/Location'
import { RootState } from '../store'

export const locationsAdapter = createEntityAdapter({
  selectId: (location: Location) => location.LOCATION_ID,
})

export const locationsSelectors = locationsAdapter.getSelectors<RootState>(
  (state) => state.locations,
)
