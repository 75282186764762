import { CSSProperties, TouchEvent, useCallback, useEffect, useState } from 'react'

interface SwipeInput {
  onSwipedLeft?: (distance: number) => void
  onSwipedRight?: (distance: number) => void
  onSwipeMove?: (distance: number) => void
  animationDuration?: number
  disabled?: boolean
  direction?: 'left' | 'right'
}

interface SwipeOutput {
  onTouchStart?: (e: TouchEvent) => void
  onTouchMove?: (e: TouchEvent) => void
  onTouchEnd?: () => void
  style?: CSSProperties
}

export const useDrawerSwipe = ({ disabled, direction = 'right', animationDuration = 150, ...input }: SwipeInput): SwipeOutput => {
  const [touchStartY, setTouchStartY] = useState(0)
  const [touchEndY, setTouchEndY] = useState(0)
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchEndX, setTouchEndX] = useState(0)
  const [transform, setTransform] = useState('translateX(0)')
  const [scrollLock, setScrollLock] = useState(false)
  const [style, setStyle] = useState<CSSProperties>({})

  const minSwipeDistance = 50
  const maxVerticalTolerance = 50

  const onTouchStart = (e: TouchEvent) => {
    setTouchEndX(0)
    setTouchEndY(0)
    setTouchStartX(e.targetTouches[0].clientX)
    setTouchStartY(e.targetTouches[0].clientY)
  }

  const onTouchMove = (e: TouchEvent) => {
    e.stopPropagation()
    
    const currentTouchX = e.targetTouches[0].clientX
    const currentTouchY = e.targetTouches[0].clientY

    setTouchEndX(currentTouchX)
    setTouchEndY(e.targetTouches[0].clientY)

    const distanceX = touchStartX - currentTouchX
    const distanceY = touchStartY - currentTouchY

    if (Math.abs(distanceX) > 25 && Math.abs(distanceY) <= maxVerticalTolerance) {
      e.stopPropagation()
      if (input.onSwipeMove) input.onSwipeMove(distanceX)

      if (direction == 'right' && distanceX >= 0) {
        setTransform('translateX(0)')
        setScrollLock(false)
      } else if (direction == 'right' && distanceX <= 0) {
        const newTransform = `translateX(${-distanceX}px)`

        setTransform(newTransform)
        setScrollLock(true)
      } else if (direction == 'left' && distanceX <= 0) {
        setTransform('translateX(0)')
        setScrollLock(false)
      } else if (direction == 'left' && distanceX >= 0) {
        const newTransform = `translateX(${distanceX}px)`

        setTransform(newTransform)
        setScrollLock(true)
      }
    }
  }

  // Update styles based on transform
  useEffect(() => {
    setStyle({
      transform: transform,
      touchAction: scrollLock ? 'none' : 'auto',
      overflowY: scrollLock ? 'hidden' : 'auto',
      transition: scrollLock ? 'transform 30ms ease' : `transform ${animationDuration}ms ease`,
    })
  }, [transform, scrollLock])

  const onTouchEnd = useCallback(() => {
    if (!touchStartX || !touchEndX) return

    const distanceX = touchStartX - touchEndX
    const distanceY = touchStartY - touchEndY
    const isLeftSwipe = distanceX > minSwipeDistance && Math.abs(distanceY) <= maxVerticalTolerance
    const isRightSwipe = distanceX < -minSwipeDistance && Math.abs(distanceY) <= maxVerticalTolerance

    setTimeout(() => {
      setTransform('translateX(0)')
      setScrollLock(false)
    }, animationDuration)

    if (isLeftSwipe && input.onSwipedLeft) {
      input.onSwipedLeft(distanceX)
    } else if (isRightSwipe && input.onSwipedRight) {
      input.onSwipedRight(distanceX)
    } else if (input.onSwipeMove) {
      input.onSwipeMove(0)
    }
  }, [touchStartX, touchEndX, touchStartY, touchEndY, input])

  return disabled ? {} : {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    style,
  }
}