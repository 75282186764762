import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import persistReducer from 'redux-persist/es/persistReducer'
import persistStore from 'redux-persist/es/persistStore'
import storage from 'redux-persist/lib/storage'
import { rootReducer } from './reducers/rootReducer'

// Combine root reducer with persist reducer.
const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  blacklist: [],
  whitelist: ['user', 'settings'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// Setup Redux Store
export type RootState = ReturnType<typeof rootReducer>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createStore = (state: any = {}) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState: state,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST'],
      },
    }),
  })
}

export const store = createStore()
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const persistor = persistStore(store)
