import { Breadcrumbs as MuiBreadcrumb, styled } from '@mui/material'

export type BreadcrumbsProps = {
  children: React.ReactNode
}

export const Breadcrumbs: React.VFC<BreadcrumbsProps> = ({ children, ...props }) => (
  <StyledBreadcrumbs {...props} separator=">" aria-label="breadcrumb">
    {children}
  </StyledBreadcrumbs>
)

const StyledBreadcrumbs = styled(MuiBreadcrumb)`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  color: ${({ theme }) => theme.palette.text.secondary};
  text-decoration: none !important;
`