import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Customer } from 'src/types/Customer'
import { replaceAllCustomers } from '../reducers/customers'

export const setupCustomerDetails = createAsyncThunk('customer/setup', async (_, { dispatch }) => {
  // Retrieve Customers
  const customers = await CarshareApiService.post<Customer>('getCustomerDetails', {})

  if (customers.results) {
    dispatch(replaceAllCustomers(customers.results))
  }
})