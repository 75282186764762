import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { NotificationCard } from 'src/components/NotificationCard/NotificationCard'
import { NotificationSidebar } from 'src/components/NotificationSidebar/NotificationSidebar'
import { useNotificationSidebarContext } from 'src/components/NotificationSidebar/NotificationSidebarContext'
import { Notification } from 'src/types/Notification'
import { useLayoutContext } from './LayoutContext'

export type NotificationSidebarFragmentProps = {
  //
}

export const NotificationSidebarFragment: React.FC<NotificationSidebarFragmentProps> = () => {
  const { setNotificationCount, isNotificationSidebarOpen, setNotificationSidebarOpen } = useNotificationSidebarContext()
  const { setSelectedBookingId } = useLayoutContext()
  const [notifications, setNotifications] = useState<Notification[]>([])

  useEffect(() => {
    CarshareApiService.get<Notification>('getCustomerNotifications').then((response) => {
      if (response.results) {
        const resultNotifications = response.results.filter((notification) => notification.notification_read === '0000-00-00 00:00:00')

        setNotifications(resultNotifications)
        setNotificationCount(resultNotifications.length)
      }
    })
  }, [])

  const onSeeBookingClick = (bookingId: string) => {
    setSelectedBookingId(bookingId)
  }

  const onDismissClick = (notificationGuid: string) => {
    CarshareApiService.post('dismissNotification', { notification_guid: notificationGuid }).then(() => {
      setNotifications(notifications.filter((notification) =>
        (notification.notification_guid !== notificationGuid) &&
        (notification.notification_read === '0000-00-00 00:00:00'), //Filter out read notifications
      ))
      setNotificationCount((prev) => Math.max(0, prev - 1))
    })
  }

  useEffect(() => {
    if (isNotificationSidebarOpen) {
      const notificationSidebarEl = document.getElementById('notificationSidebarContent')

      if (notificationSidebarEl) {
        notificationSidebarEl.focus()
      }
    }
  }, [isNotificationSidebarOpen])

  return (
    <NotificationSidebar open={isNotificationSidebarOpen} onClose={() => setNotificationSidebarOpen(false)}>
      {notifications.map((notification, idx) => (
        <NotificationCard
          key={idx}
          title={notification.notification_heading}
          time={notification.notification_htime}
          onSeeBookingClick={() => onSeeBookingClick(notification.BOOKING_ID)}
          onMarkReadClick={() => onDismissClick(notification.notification_guid)}
          type={'neutral'}
        >
          <div dangerouslySetInnerHTML={{ __html: notification.notification_contents.replace(/\n\n/g, '<br />') }} />
        </NotificationCard>
      ))}
    </NotificationSidebar>
  )
}