import { faChevronCircleRight } from '@fortawesome/pro-solid-svg-icons'
import { Paper, Typography, styled } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { BookingStatusMap } from 'src/styles/themes'
import { Booking } from 'src/types/Booking'
import { Icon } from '../Icon/Icon'

export type BookingCardProps = {
  onClick?: () => void
  booking: Booking
}

export const BookingCard: React.FC<BookingCardProps> = ({ onClick, booking }) => {
  const formattedDate = dayjs(booking.booking_date_pickup).format('D MMM YYYY')
  const formattedTime = dayjs(booking.booking_time_pickup, 'HH:mm:ss').format('h:mm A')
  const isBookingCancelled = booking.booking_cancelled !== '0000-00-00 00:00:00'
  const statusType = BookingStatusMap[isBookingCancelled ? 'Cancelled' : booking.status] || BookingStatusMap['']

  return (
    <StyledPaper elevation={0} onClick={onClick}>
      <StyledTitle variant="h2">
        {formattedDate} - {formattedTime}
      </StyledTitle>
      <StyledDescription>
        {booking.location_pickup_address}
      </StyledDescription>
      <StyledVehicleText variant="body1">
        Vehicle: {booking.make_name} {booking.model_name} - {booking.vehicle_rego_number}
        <br />
        Ref: {booking.booking_reference}
        <br />
        Status: <StyledStatus $type={statusType}>{isBookingCancelled ? 'Cancelled' : booking.status}</StyledStatus>
      </StyledVehicleText>
      <StyledCheveron icon={faChevronCircleRight} />
    </StyledPaper>
  )
}


const StyledPaper = styled(Paper)`
  position: relative;
  padding: ${(props) => props.theme.typography.pxToRem(16)};
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.theme.palette.custom.formFieldBorder};
  transition: border ${(props) => props.theme.transitions.duration.standard}ms ease-in-out, box-shadow ${(props) => props.theme.transitions.duration.standard}ms ease-in-out;
  cursor: pointer;

  #cta {
    opacity: 0;
    transition: opacity ${(props) => props.theme.transitions.duration.standard}ms ease-in-out;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    box-shadow: ${(props) => props.theme.palette.custom.formFieldBoxShadow};

    #cta {
      opacity: 1;
    }
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.typography.pxToRem(20)};
  }
`

const StyledTitle = styled(Typography)`
  font-weight: 500;
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: ${(props) => props.theme.typography.pxToRem(18)};
  }
`

const StyledDescription = styled(Typography)`
  font-weight: 400;
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  color: ${(props) => props.theme.palette.text.secondary};
  margin-top: ${(props) => props.theme.typography.pxToRem(2)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-top: ${(props) => props.theme.typography.pxToRem(4)};
    font-size: ${(props) => props.theme.typography.pxToRem(16)};
  }
`

const StyledVehicleText = styled(Typography)`
  font-weight: 400;
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  margin-top: ${(props) => props.theme.typography.pxToRem(8)};
`

const StyledCheveron = styled(Icon)`
  position: absolute;
  right: ${(props) => props.theme.typography.pxToRem(12)};
  top: calc(50% - ${(props) => props.theme.typography.pxToRem(8)});
  font-size: ${(props) => props.theme.typography.pxToRem(20)};
  color: ${(props) => props.theme.palette.text.primary};
`

const StyledStatus = styled('strong', { shouldForwardProp: (prop) => prop !== '$type' }) <{
  $type: 'success' | 'error' | 'warning' | 'info'
}>`
  font-weight: 500;
  ${(props) => props.$type === 'success' && `
    color: ${props.theme.palette.primary.main};
  `}

  ${(props) => props.$type === 'error' && `
    color: ${props.theme.palette.error.main};
  `}

  ${(props) => props.$type === 'warning' && `
    color: ${props.theme.palette.warning.main};
  `}

  ${(props) => props.$type === 'info' && `
    color: ${props.theme.palette.info.main};
  `}
`