import { Alert, FormControl, styled } from '@mui/material'
import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Button } from 'src/components/Button/Button'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { Link } from 'src/components/Link/Link'
import { LoginCard } from 'src/components/LoginCard/LoginCard'
import { RequestHandler, useApiRequest } from 'src/hooks/useApiRequest'
import { useAppDispatch } from 'src/store/store'
import { User } from 'src/types/User'

export type ResetPasswordPageProps = {
  //
}

export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = () => {
  const [email, setEmail] = useState('')
  const { loading, complete, errorMessage, request } = useApiRequest()
  const dispatch = useAppDispatch()

  const onLogin = useCallback(async () => {
    const loginHandler: RequestHandler<User> = () => (
      CarshareApiService.post('resetPassword', {
        email: email,
        domain: window.location.host,
      })
    )

    await request<User>(loginHandler)
  }, [email, dispatch, request])

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onLogin()
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  return (
    <StyledLoginPage>
      <LoginCard>
        {!complete && !errorMessage && (
          <StyledAlert severity={'info'} icon={false}>
            Enter your email address and press 'Submit' to receive a password reset email.
          </StyledAlert>
        )}
        {complete && (
          <StyledAlert severity={'info'} icon={false}>
            Check your inbox for the password reset link and instruction.
          </StyledAlert>
        )}
        {errorMessage && (
          <StyledAlert severity={'error'} icon={false}>
            {errorMessage}
          </StyledAlert>
        )}
        <StyledForm onSubmit={onSubmit} >
          <FormControl required error={!!errorMessage}>
            <TextFieldLabel htmlFor="email">
              Email
            </TextFieldLabel>
            <TextField
              id="email"
              type="text"
              required
              autoFocus
              autoComplete="username email"
              aria-describedby="email"
              onChange={handleEmailChange}
              fullWidth
            />
          </FormControl>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading || !email}
          >
            Submit
          </StyledSubmitButton>
          <StyledLink href="/">
            Back to login
          </StyledLink>
        </StyledForm>
      </LoginCard>
    </StyledLoginPage>
  )
}

const StyledLoginPage = styled('main')`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.background.default};
`

const StyledForm = styled('form')`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(Link)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-top: ${props => props.theme.typography.pxToRem(16)};
  text-align: right;
`

const StyledSubmitButton = styled(Button)`
  margin-top: ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(28)};
  }
`

const StyledAlert = styled(Alert)`
  align-self: stretch;
  margin-bottom: ${props => props.theme.typography.pxToRem(16)};
`
