import { Alert as MuiAlert, AlertProps as MuiAlertProps, keyframes, styled } from '@mui/material'

import React from 'react'
import { useAlertContext } from './AlertProvider'


export type AlertProps = Omit<MuiAlertProps, 'variant'> & {
  //
}

// es-lint-disable-next-line @typescript-eslint/no-unused-vars
export const Alert: React.FC<AlertProps> = ({ ...props }) => {
  const { successMessage, setSuccessMessage, errorMessage, setErrorMessage, infoMessage, setInfoMessage } = useAlertContext()

  const onSuccessMessageClose = () => {
    setSuccessMessage(null)
  }

  const onInfoMessageClose = () => {
    setInfoMessage(null)
  }

  const onErrorMessageClose = () => {
    setErrorMessage(null)
  }

  return (
    <>
      {successMessage && (
        <StyledAlert severity="success" {...props} onClose={onSuccessMessageClose}>
          {successMessage}
        </StyledAlert>
      )}
      {errorMessage && (
        <StyledAlert severity="error" {...props} onClose={onErrorMessageClose}>
          {errorMessage}
        </StyledAlert>
      )}
      {infoMessage && (
        <StyledAlert severity="info" {...props} onClose={onInfoMessageClose}>
          {infoMessage}
        </StyledAlert>
      )}
    </>
  )
}

const slideIn = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const slideOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`

const StyledAlert = styled(MuiAlert)`
  position: fixed;
  bottom: 64px;
  left: 0;
  right: 0;
  z-index: ${(props) => props.theme.zIndex.appBar + 2000};
  animation: ${slideIn} 0.5s forwards;
  
  &.MuiAlert-exit {
    animation: ${slideOut} 0.5s forwards;
  }

  .MuiAlert-action {
    padding-left: 4px;
  }

  @media all and (display-mode: standalone) {
    bottom: 80px;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    position: unset;
    margin: ${props => props.theme.typography.pxToRem(16)} 0;
    border-radius: ${props => props.theme.typography.pxToRem(8)};
  }
`