import { combineReducers, createAction } from '@reduxjs/toolkit'
import { reducer as bookings, initialState as bookingsInitialState } from './bookings'
import { reducer as contents, initialState as contentsInitialState } from './contents'
import { reducer as customers, initialState as customersInitialState } from './customers'
import { reducer as images, initialState as imagesInitialState } from './images'
import { reducer as locations, initialState as locationsInitialState } from './locations'
import { reducer as members, initialState as membersInitialState } from './members'
import { reducer as passengers, initialState as passengersInitialState } from './passengers'
import { reducer as settings, initialState as settingsInitialState } from './settings'
import { reducer as user, initialState as userInitialState } from './user'
import { reducer as vehicles, initialState as vehiclesInitialState } from './vehicles'


export const appReducer = combineReducers({
  user,
  settings,
  bookings,
  vehicles,
  customers,
  locations,
  members,
  images,
  passengers,
  contents,
})

export const RESET_STATE = 'RESET_STATE'

export const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    return appReducer({
      user: userInitialState,
      settings: settingsInitialState,
      bookings: bookingsInitialState,
      vehicles: vehiclesInitialState,
      customers: customersInitialState,
      locations: locationsInitialState,
      members: membersInitialState,
      images: imagesInitialState,
      passengers: passengersInitialState,
      contents: contentsInitialState,
    }, action)
  }

  return appReducer(state, action)
}

export const resetState = createAction(RESET_STATE)

export default rootReducer
