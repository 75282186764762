import { faArrowRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { Box, Collapse, Divider, Fade, TextField, Typography, styled } from '@mui/material'
import { Dayjs } from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { Icon } from 'src/components/Icon/Icon'
import { MobileSlider } from 'src/components/MobileSlider/MobileSlider'
import { Picker } from 'src/components/Picker/Picker'
import { PickerColumn } from 'src/components/Picker/PickerColumn'
import { PickerItem } from 'src/components/Picker/PickerItem'
import { pxToRem } from 'src/styles/themes'
import { FrequencyType, RecurrenceContext } from './RecurrenceContext'

const FREQUENCY_OPTIONS = [
  {
    key: FrequencyType.None,
    title: 'Does not repeat',
    unit: '',
  },
  {
    key: FrequencyType.Daily,
    title: 'Every day',
    unit: 'day',
  },
  {
    key: FrequencyType.Weekly,
    title: 'Every week',
    unit: 'week',
  },
  {
    key: FrequencyType.Fortnightly,
    title: 'Every fortnight',
    unit: 'weeks',
  },
  {
    key: FrequencyType.Monthly,
    title: 'Every month',
    unit: 'month',
  },
]


export type RecurrenceSelectorProps = {
  isMobileSlider?: boolean
  isEditForm: boolean
}

export const RecurrenceSelector: React.FC<RecurrenceSelectorProps> = ({ isMobileSlider, isEditForm }) => {
  const [pickerValue, setPickerValue] = useState({
    numRepetition: '1',
    frequency: 'week',
  })
  const [isPickerInit, setIsPickerInit] = useState(false)
  const [customFrequencyTitle, setCustomFrequencyTitle] = useState<string | null>(null)
  const [endDateErrorMessage, setEndDateErrorMessage] = useState<string | null>(null)

  type SelectionsType = {
    [key: string]: string[];
  }

  const selections: SelectionsType = {
    numRepetition: ['1', '2', '3'],
    frequency: ['week', 'month'],
  }
  const { recurrence, setRecurrence } = useContext(
    RecurrenceContext,
  )

  const handleNumberOfRepetitionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) return

    const numRepetition = parseInt(event.target.value || '1')

    setRecurrence((prev) => {
      const newRecurrence = {
        ...prev,
        numberOfRepetitions: numRepetition,
      }

      return newRecurrence
    })

    if (numRepetition === 1) return

    setCustomFrequencyTitle(`Repeats every ${numRepetition} ${pickerValue.frequency}${numRepetition > 1 ? 's' : ''}`)
  }

  const handleFrequencyChange = (value: string) => {
    if (value === 'Custom') return

    if (value === FrequencyType.Fortnightly) {
      setRecurrence((prev) => ({
        ...prev,
        numberOfRepetitions: 2,
        frequency: FrequencyType.Weekly,
      }))
      setCustomFrequencyTitle('Every fortnight')

      return
    }

    setCustomFrequencyTitle(null)
    setRecurrence((prev) => ({
      ...prev,
      numberOfRepetitions: 1,
      frequency: value as FrequencyType,
    }))
  }

  const handleEndDateChange = (date: Dayjs | null) => {
    setRecurrence((prev) => {
      const newRecurrence = {
        ...prev,
        endDate: date,
      }

      return newRecurrence
    })
  }

  useEffect(() => {
    if (!isPickerInit) return

    setRecurrence((prev) => {
      const newRecurrence = {
        ...prev,
        numberOfRepetitions: parseInt(pickerValue.numRepetition),
        frequency: FREQUENCY_OPTIONS.find(val => val.unit === pickerValue.frequency)?.key || FrequencyType.None,
      }

      return newRecurrence
    })

    setCustomFrequencyTitle(`Repeats every ${pickerValue.numRepetition} ${pickerValue.frequency}${parseInt(pickerValue.numRepetition) > 1 ? 's' : ''}`)
  }, [pickerValue, isPickerInit])

  return (
    <>
      <TextFieldLabel>Recurrence</TextFieldLabel>
      <Dropdown
        fullHeight
        disabled={isEditForm}
        name="frequency"
        value={(customFrequencyTitle ? customFrequencyTitle : FREQUENCY_OPTIONS.find((item) => item.key === recurrence.frequency)?.title) || 'Custom...'}
        onSelectItem={handleFrequencyChange}
      >
        {FREQUENCY_OPTIONS.map((option, idx) => (
          <DropdownItem
            key={idx}
            data-value={option.key}
          >
            {option.title}
          </DropdownItem>
        ))}
        {isMobileSlider && <Divider />}
        {isMobileSlider &&
          <MobileSlider menuItem data-value={customFrequencyTitle ? customFrequencyTitle : 'Custom'} level={1} idx={2}>
            Custom...
          </MobileSlider>
        }
      </Dropdown>

      {isMobileSlider &&
        <MobileSlider navigation level={2} idx={3}>
          <TextFieldLabel sx={{ display: 'flex', alignItems: 'center', marginTop: pxToRem(16), marginLeft: pxToRem(16), fontWeight: 500 }}>
            <StyledIcon icon={faArrowRotateRight} />
            Every {pickerValue.numRepetition} {pickerValue.frequency}{parseInt(pickerValue.numRepetition) > 1 ? 's' : ''}
          </TextFieldLabel>
          <Picker
            value={pickerValue}
            onChange={(value, _) => {
              setPickerValue({ ...value })
              setIsPickerInit(true)
            }}
          >
            <PickerColumn key={'numRepetition'} name={'numRepetition'}>
              {selections.numRepetition.map(option => (
                <PickerItem key={option} value={option}>
                  <Typography variant="body1">
                    {option}
                  </Typography>
                </PickerItem>
              ))}
            </PickerColumn>
            <PickerColumn key={'frequency'} name={'frequency'}>
              {selections.frequency.map(option => (
                <PickerItem key={option} value={option}>
                  <Typography variant="body1">
                    {option}{parseInt(pickerValue.numRepetition) > 1 ? 's' : ''}
                  </Typography>
                </PickerItem>
              ))}
            </PickerColumn>
          </Picker>
        </MobileSlider>
      }

      <Collapse in={recurrence.frequency !== FrequencyType.None}>
        <Fade in={recurrence.frequency !== FrequencyType.None}>
          <div>
            {!isMobileSlider && recurrence.frequency !== FrequencyType.Daily && customFrequencyTitle !== 'Every fortnight' && (
              <>
                <TextFieldLabel sx={{ marginTop: pxToRem(16) }}>Repeats Every</TextFieldLabel>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <TextField
                    name="number-of-repetition"
                    type="number"
                    value={recurrence.numberOfRepetitions}
                    onChange={handleNumberOfRepetitionChange}
                    inputProps={{ maxLength: 12 }}
                    sx={{ width: pxToRem(64) }}
                  />
                  <Typography variant="body1" sx={{ marginLeft: pxToRem(8) }}>
                    {`${FREQUENCY_OPTIONS.find((item) => item.key === recurrence.frequency)?.unit}${recurrence.numberOfRepetitions && recurrence.numberOfRepetitions > 1 ? 's' : ''}`}
                  </Typography>
                </Box>
              </>
            )}

            <TextFieldLabel sx={{ marginTop: pxToRem(16) }}>Repeats Until</TextFieldLabel>
            <DatePicker
              value={recurrence.endDate}
              format="DD/MM/YYYY"
              disablePast
              minDate={recurrence.startDate}
              maxDate={recurrence.startDate.endOf('quarter')}
              onChange={(date) => {
                if (date && (date as Dayjs)?.isValid()) {
                  handleEndDateChange(date as Dayjs)
                }
              }}
              onAccept={(date) => {
                if (date) {
                  handleEndDateChange(date as Dayjs)
                }
              }}
              onError={(error) => {
                if (!error) {
                  setEndDateErrorMessage(null)
                } else if (error === 'maxDate') {
                  setEndDateErrorMessage('End date must occur before the end of quarter')
                } else if (error === 'minDate') {
                  setEndDateErrorMessage('End date must occur after the pickup date')
                } else {
                  setEndDateErrorMessage(`Invalid date: ${error}`)
                }
              }}
              error={endDateErrorMessage}
            />
          </div>
        </Fade>
      </Collapse>
    </>
  )
}

const StyledIcon = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-right: ${props => props.theme.typography.pxToRem(8)};
`