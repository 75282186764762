import { Badge, Box, Divider, Grid, Typography, styled } from '@mui/material'
import { Button } from 'src/components/Button/Button'
import { TextField } from 'src/components/Form/TextField'
import { Icon } from 'src/components/Icon/Icon'


export const StyledMobileAvailabilityFilter = styled(Grid)`
  width: 100%;
  position: relative;

  &::after {
    content: 'to';
    font-size: ${(props) => props.theme.typography.pxToRem(16)};
    font-weight: 400;
    font-family: ${(props) => props.theme.typography.fontFamily};
    position: absolute;

    top: ${(props) => props.theme.typography.pxToRem(14)};
    left: calc(50% - ${(props) => props.theme.typography.pxToRem(8)});
    height: ${(props) => props.theme.typography.pxToRem(28)};
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`

export const StyledFilterButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== '$numFilterChanges',
}) <{ $numFilterChanges?: number }>`
  margin-right: ${(props) => props.$numFilterChanges === 0 ? props.theme.typography.pxToRem(-18) : props.theme.typography.pxToRem(4)};
  font-weight: 500;
  font-size: ${(props) => props.theme.typography.pxToRem(14)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: unset;
    font-weight: unset;
    margin-left: ${(props) => props.theme.typography.pxToRem(-14)};
    margin-right: ${(props) => props.theme.typography.pxToRem(8)};
  }
`

export const StyledBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.typography.pxToRem(8)};
`

export const StyledExploreHeader = styled(Grid)`
  justify-content: space-between;
  position: fixed;
  top: ${(props) => props.theme.layout.navHeaderHeight};
  left: 0;
  z-index: ${(props) => props.theme.zIndex.appBar};
  background-color: ${(props) => props.theme.palette.background.paper};
  padding: 0 ${(props) => props.theme.typography.pxToRem(16)};
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.03);

  ${(props) => props.theme.breakpoints.up('sm')} {
    justify-content: flex-start;
    position: static;
    top: unset;
    left: unset;
    border-bottom: unset;
    box-shadow: unset;
  }

  padding-top: ${(props) => props.theme.typography.pxToRem(10)};
  padding-bottom: ${(props) => props.theme.typography.pxToRem(4)};
`

export const StyledMobilePlaceholder = styled(Box)`
  height: ${(props) => props.theme.typography.pxToRem(158)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`

export const StyledClearFilterGrid = styled(Grid)`
  display: none;

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: unset;
  }
`

export const StyledFilterRule = styled(Typography)`
  min-width: ${(props) => props.theme.typography.pxToRem(165)};
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  font-weight: 300;
`

export const StyledVerticleDivider = styled('div')`
  display: none;
  height: ${(props) => props.theme.typography.pxToRem(24)};
  border-left: 1px solid #D2D2D2;
  margin: 0 ${(props) => props.theme.typography.pxToRem(14)};
  width: 1px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: unset;
  }
`

export const StyledMobileSearchBar = styled(TextField)`
  width: 100%;

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`

export const StyledDesktopSearchBar = styled(TextField)`
  display: none;

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: unset;
    margin-left: ${(props) => props.theme.typography.pxToRem(24)};

    .MuiOutlinedInput-root {
      width: ${(props) => props.theme.typography.pxToRem(220)};
    }
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-left: ${(props) => props.theme.typography.pxToRem(72)};
    .MuiOutlinedInput-root {
      width: 100%;
    }
  }
`

export const StyledClose = styled(Icon)`
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  color: ${(props) => props.theme.palette.text.primary};
`

export const StyledSelectDateButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== '$error',
}) <{ $error?: boolean }>`
  margin-right: ${(props) => props.theme.typography.pxToRem(-14)};
  margin-left: ${(props) => props.theme.typography.pxToRem(-14)};
  font-weight: 500;
  font-size: ${(props) => props.theme.typography.pxToRem(14)};

  ${(props) => props.$error && `
    box-shadow: 0 0 0 2px ${props.theme.palette.error.main};
  `}
`

export const StyledDivider = styled(Divider)`
  position: absolute;
  left: 0;
  right: 0;
`