import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Content } from 'src/types/Content'
import { ContentsAdapter } from '../adapters/contents'

export const initialState = ContentsAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    addMultipleContents: (state, action: PayloadAction<Content[]>) => {
      ContentsAdapter.upsertMany(state, action.payload)
    },
    replaceAllContents: (state, action: PayloadAction<Content[]>) => {
      ContentsAdapter.setAll(state, action.payload)
    },

  },
})

export const { addMultipleContents, replaceAllContents } = actions