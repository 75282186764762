import { initialFilterState } from 'src/store/reducers/settings'
import { FilterOptions } from 'src/types/FilterOptions'

export const countFilterOptionsChanges = (filterOptions: FilterOptions) => {
  let changes = 0

  if (!(initialFilterState.fromDate === filterOptions.fromDate)) changes++
  if (!(initialFilterState.toDate === filterOptions.toDate)) changes++
  if (initialFilterState.make !== filterOptions.make) changes++
  if (initialFilterState.range !== filterOptions.range) changes++
  if (initialFilterState.seats !== filterOptions.seats) changes++
  if (initialFilterState.luggages !== filterOptions.luggages) changes++
  if (initialFilterState.fuelType !== filterOptions.fuelType) changes++
  if (initialFilterState.transmission !== filterOptions.transmission) changes++
  if (initialFilterState.attributes?.length !== filterOptions.attributes?.length
    || !(initialFilterState.attributes ?? []).every((value) => filterOptions.attributes.includes(value))) changes++
  if (initialFilterState.features?.length !== filterOptions.features?.length
    || !(initialFilterState.features ?? []).every((value) => filterOptions.features.includes(value))) changes++

  return changes
}
