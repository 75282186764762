import dayjs from 'dayjs'

export const formatRecurrenceRule = (recurrenceType: string, recurrenceEvery: number, recurrenceUntil: string) => {
  if (recurrenceType === 'none') return 'None'

  let frequency = 'day'

  if (recurrenceType === 'weekly') frequency = 'week'
  if (recurrenceType === 'monthly') frequency = 'month'

  const repetitions = recurrenceEvery > 1 ? `Every ${recurrenceEvery} ${frequency}s` : recurrenceType
  const endDate = recurrenceUntil ? ` until ${dayjs(recurrenceUntil).format('D MMM YYYY')}` : ''

  return `${repetitions}${endDate}`
}