import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Manager } from 'src/types/Manager'
import { membersAdapter } from '../adapters/members'

export const initialState = membersAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'members',
  initialState,
  reducers: {
    addMultipleMembers: (state, action: PayloadAction<Manager[]>) => {
      membersAdapter.upsertMany(state, action.payload)
    },
    removeAllMembers: (state) => {
      membersAdapter.removeAll(state)
    },
  },
})

export const { addMultipleMembers, removeAllMembers } = actions