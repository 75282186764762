import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Location } from 'src/types/Location'
import { addMultipleLocations } from '../reducers/locations'

export const setupLocations = createAsyncThunk('locations/setup', async (_, { dispatch }) => {
  // Retrieve Bookings
  const locations = await CarshareApiService.post<Location>('getLocation', {
    limit: 1000,
  })

  if (locations.results) {
    dispatch(addMultipleLocations(locations.results))
  }
})