import { styled } from '@mui/material'
import React from 'react'

export type QuillPreviewProps = {
  htmlContent: string
}

export const QuillPreview: React.FC<QuillPreviewProps> = ({ htmlContent }) => (
  <StyledQuillPreview
    id="quill-preview"
    dangerouslySetInnerHTML={{ __html: htmlContent }}
  >
  </StyledQuillPreview>
)


const StyledQuillPreview = styled('div')`
  font-family: 'Inter', 'Roboto', sans-serif;
  line-height: 1.5;
  letter-spacing: 0.02em;
  width: 100%;
  font-weight: 300;

  & p {
    margin-bottom: ${props => props.theme.typography.pxToRem(16)};
    font-size: ${props => props.theme.typography.pxToRem(16)};

    .ql-size-large {
      font-size: ${props => props.theme.typography.pxToRem(20)};
    }

    .ql-size-huge {
      font-size: ${props => props.theme.typography.pxToRem(24)};
    }
  }

  & li[data-list="bullet"] {
    list-style-type: disc;
  }

  & strong {
    font-weight: 500;
    margin-bottom: 0 !important;
  }
`