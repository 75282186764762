import { styled, useMediaQuery, useTheme } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Alert } from 'src/components/Alert/Alert'
import { AlertContext, useAlertState } from 'src/components/Alert/AlertProvider'
import { Modal } from 'src/components/Modal/Modal'
import { NotificationSidebarContext } from 'src/components/NotificationSidebar/NotificationSidebarContext'
import { BookingDetails } from 'src/pages/Bookings/BookingDetails'
import { ProtectedRoute } from '../Route/ProtectedRoute'
import { HeaderFragment } from './HeaderFragment'
import { LayoutContext } from './LayoutContext'
import { NavigationFragment } from './NavigationFragment'
import { NotificationSidebarFragment } from './NotificationSidebarFragment'

export type LayoutProps = {
  //
}


export const Layout: React.FC<LayoutProps> = () => {
  const [isNotificationSidebarOpen, setNotificationSidebarOpen] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0)
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(null)
  const [isBookingDetailsOpen, setBookingDetailsOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const alertState = useAlertState()

  const notificationSidebarContext = {
    isNotificationSidebarOpen,
    setNotificationSidebarOpen,
    notificationCount,
    setNotificationCount,
  }

  // Open booking details modal when booking id is set
  const onBookingDetailsClose = () => {
    setBookingDetailsOpen(false)

    setTimeout(() => {
      setSelectedBookingId(null)

      if (window.location.href.indexOf('#') > -1) {
        window.history.pushState('', document.title, window.location.pathname + window.location.search)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        document.body.style.overflow = 'auto'
      }
    }, 150)
  }

  const onSetSelectedBookingId = (bookingId: string) => {
    setSelectedBookingId(bookingId)

    if (bookingId) {
      setBookingDetailsOpen(true)

      if (window.location.href.indexOf(`#${bookingId}`) === -1) {
        window.location.href = `#${bookingId}`
        document.body.style.overflow = 'hidden'
      }
    }
  }

  useEffect(() => {
    const bookingId = window.location.hash.replace('#', '')

    document.body.style.overflow = 'auto'

    if (bookingId) {
      setSelectedBookingId(bookingId)

      setTimeout(() => {
        setBookingDetailsOpen(true)
        document.body.style.overflow = 'hidden'
      }, 50)
    }
  }, [])

  return (
    <LayoutContext.Provider value={{ selectedBookingId, setSelectedBookingId: onSetSelectedBookingId }}>
      <NotificationSidebarContext.Provider value={notificationSidebarContext}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AlertContext.Provider value={alertState}>
            <HeaderFragment />
            <NavigationFragment />
            <StyledMain>
              <ProtectedRoute key={window.location.pathname}>
                <Alert />
                <Outlet />
              </ProtectedRoute>
            </StyledMain>
            <NotificationSidebarFragment />

            <div id="session-expired-dialog" />
            <div id="overlay-page" />
            <div id="modal" />
            <div id="slider" />

            <Modal
              open={isBookingDetailsOpen}
              fullWidth
              showBackground={false}
              portal="#overlay-page"
              transition={isMobile ? 'slide-right' : 'opacity'}
              animationDuration={250}
              onClose={onBookingDetailsClose}
            >
              <BookingDetails
                bookingId={selectedBookingId || ''}
                onClose={onBookingDetailsClose}
              />
            </Modal>
          </AlertContext.Provider>
        </LocalizationProvider>
      </NotificationSidebarContext.Provider>
    </LayoutContext.Provider>
  )
}

const StyledMain = styled('main', {
  shouldForwardProp: (prop) => prop !== '$isNavOpen',
}) <{ $isNavOpen?: boolean }>`
  position: relative;
  margin-top: ${(props) => props.theme.layout.navHeaderHeight};
`
